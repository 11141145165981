import {makeObservable, observable} from "mobx";

import {AdminApi} from "../../../api/api";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";

export class MediaPublishingPageStore {
    @observable
    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PageStoreConstructorOptions) {
        makeObservable(this);

        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;
    }

    public async initialize({query}: any = {}) {
        this.stores.authenticationStore.checkAnyPermissionExists(Permission.EDIT_MEDIA);
    }
}
