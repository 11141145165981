import {Button, InputGroup, NonIdealState} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";

import {useAdminApi} from "../../../api/api-context";
import {Link} from "../../../routes";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {authenticationStore} from "../../../stores/authentication-store";
import {useMediaListStore} from "../../../stores/media-list-store";
import {createMediaInfo, MediaInfoState} from "../../../utils/media-util";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import MediaCard from "../../organisms/media-card/media-card";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {MediaListPageStore} from "./media-list-page-store";

const TopContainerDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
`;

const BorderSpan = styled.span`
    display: block;
    width: 100%;
    height: 1px;
    background-color: #c5cbd3;
`;

const SearchResultUl = styled.ul`
    display: flex;
    gap: 16px;
    padding: 0;
    flex-wrap: wrap;
`;

const MediaList = styled.li`
    list-style: none;
    width: 283px;
`;

const MediaListPage: React.FC<{pageStore: MediaListPageStore}> = observer(({pageStore}) => {
    const adminApi = useAdminApi();
    const [inputValue, setInputValue] = useState("");
    const [initialMediaInfoList, setInitialMediaInfoList] = useState<MediaInfoState[]>([]);
    const [filteredMediaInfoList, setFilteredMediaInfoList] = useState<MediaInfoState[]>([]);
    const [isSorting, setIsSorting] = useState(false);
    const [error, setError] = useState(false);

    const mediaInfoState = useMediaListStore((state) => state.infoList);
    const setMediaList = useMediaListStore((state) => state.setInfo);
    const setActiveMediaInfo = useActiveMediaStore((state) => state.setActiveMediaInfo);
    const aquaAuth = JSON.parse(localStorage.getItem("aquaAuth") || "{}");
    const isAdmin = aquaAuth.scope && aquaAuth.scope.includes("ADMIN");
    const {hasManageMediaPermission} = authenticationStore;

    const fetchMediaList = async () => {
        try {
            let result;
            if (isAdmin) {
                result = await adminApi.getMediaList();
            } else {
                result = await adminApi.getMediaListMember();
            }

            if (result) {
                const mediaList: MediaInfoState[] = result.mediaList.map(createMediaInfo);
                setMediaList(mediaList);
            }
        } catch (e) {
            console.error("メディア一覧の取得にエラーが発生しました：", e);
            setError(true);
        }
    };

    useEffect(() => {
        fetchMediaList();
        setActiveMediaInfo({} as MediaInfoState);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const filteredMedia = initialMediaInfoList.filter((mediaInfo) =>
            mediaInfo.mediaName.includes(inputValue),
        );
        setFilteredMediaInfoList(filteredMedia);
        setIsSorting(!!inputValue);
    }, [inputValue, initialMediaInfoList]);

    useEffect(() => {
        setInitialMediaInfoList(mediaInfoState);
        mediaInfoState.forEach((mediaInfo) => {
            mediaInfo.mediaId;
        });
    }, [mediaInfoState]);

    const mediaListToShow = isSorting ? filteredMediaInfoList : initialMediaInfoList;

    return (
        <AppLayout
            breadcrumb={<AdminBreadcrumb factory={(builder) => builder.mediaList().build()} />}
        >
            <TopContainerDiv>
                <InputGroup
                    large={true}
                    placeholder="Search..."
                    leftIcon="search"
                    type="search"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
                {(isAdmin || hasManageMediaPermission) && (
                    <Link route="/admin/media-create">
                        <Button icon="add" text="メディアを追加" />
                    </Link>
                )}
            </TopContainerDiv>
            <BorderSpan />
            <SearchResultUl>
                {error ? (
                    <NonIdealState
                        icon="error"
                        title="メディア一覧の取得に失敗しました。"
                        description="再度ページを読み込んでください。"
                    />
                ) : (
                    <>
                        {mediaListToShow.length === 0 ? (
                            <NonIdealState
                                icon="search"
                                title="一致するメディアは見つかりませんでした。"
                                description="検索条件を変更して再度検索してください。"
                            />
                        ) : (
                            mediaListToShow.map((mediaInfo) => (
                                <MediaList key={mediaInfo.mediaId}>
                                    <MediaCard
                                        mediaId={mediaInfo.mediaId}
                                        mediaImgSrc={mediaInfo.mediaThumbnail}
                                        mediaTitle={mediaInfo.mediaName}
                                        mediaUrl={mediaInfo.mediaUrl}
                                    />
                                </MediaList>
                            ))
                        )}
                    </>
                )}
            </SearchResultUl>
        </AppLayout>
    );
});

export default MediaListPage;
