import {Intent} from "@blueprintjs/core";
import {computed, makeObservable, observable, runInAction} from "mobx";

import {ValidationResultItem} from "./validation-result-item";

export class ValidationResult {
    @observable
    public items: ValidationResultItem[] = [];
    isValid: any;
    isError: any;
    intent: Intent | undefined;

    constructor() {
        makeObservable(this);
    }

    @computed
    public get isEmpty() {
        return this.items.length === 0;
    }

    @computed
    public get simpleMessage() {
        return this.items.map((item) => item.errorMessage).join(", ");
    }

    // Just a helper for mobx
    public run(action: (v: ValidationResult) => void) {
        runInAction(() => action(this));
    }

    // TODO: support validationInProgress
}

export const ValidationResultEmpty = new ValidationResult();
