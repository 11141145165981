import {Icon, Spinner} from "@blueprintjs/core";
import {Imagefront} from "@syginc/imagefront-client";
import * as React from "react";
import {useCallback, useRef, useState} from "react";

import {IAquaEditor} from "../../../editor/aqua-editor";
import {ImageUploadInput, ImageUploadInputRef} from "../image-upload-input/image-upload-input";
import {UploadImageResult} from "../image-upload-input/upload-image";
import {
    UploadableImageDiv,
    UploadableImageIconDiv,
    UploadableImageImg,
    UploadableImagePlaceholderDiv,
} from "./uploadable-image-styles";

export interface IUploadableImageProps {
    aquaEditor?: IAquaEditor;
    imagefront?: Imagefront;
    className?: string;
    imageUri: string;
    onUploaded?: (uri: string) => void;
    isEditable: boolean;
}

export const UploadableImage: React.FC<IUploadableImageProps> = ({
    aquaEditor,
    imagefront,
    className,
    imageUri,
    isEditable,
    onUploaded,
}) => {
    const [isUploading, setIsUploading] = useState(false);
    const imageInputRef = useRef<ImageUploadInputRef>(null);

    const onClick = useCallback(() => {
        imageInputRef.current?.startUpload();
    }, []);

    const onInputUploading = useCallback(() => {
        setIsUploading(true);
    }, []);

    const onInputUploaded = useCallback(
        (result: UploadImageResult) => {
            setIsUploading(false);

            if (result.status === "success") {
                if (onUploaded) {
                    onUploaded(result.url);
                }
            } else {
                alert("Failed to upload: " + result.error);
            }
        },
        [onUploaded],
    );

    if (!aquaEditor && !imagefront) {
        return <Spinner />;
    }

    return (
        <UploadableImageDiv className={className}>
            {imageUri ? <UploadableImageImg src={imageUri} /> : <UploadableImagePlaceholderDiv />}
            {isEditable && (
                <>
                    <UploadableImageIconDiv onClick={onClick}>
                        {isUploading ? <Spinner /> : <Icon icon="add" iconSize={Icon.SIZE_LARGE} />}
                    </UploadableImageIconDiv>
                    {aquaEditor && (
                        <ImageUploadInput
                            ref={imageInputRef}
                            imagefront={aquaEditor.getImagefrontClient()}
                            imageConvertType="afi"
                            onUploading={onInputUploading}
                            onUploaded={onInputUploaded}
                        />
                    )}
                    {imagefront && (
                        <ImageUploadInput
                            ref={imageInputRef}
                            imagefront={imagefront}
                            imageConvertType="afi"
                            onUploading={onInputUploading}
                            onUploaded={onInputUploaded}
                        />
                    )}
                </>
            )}
        </UploadableImageDiv>
    );
};
