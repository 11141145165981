import {Button, Dialog, InputGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

import {useAdminApi} from "../../../api/api-context";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {DialogBox, DialogTextBox} from "../../molecules/media-confirm-dialog/DialogComponents";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {MediaDeletePageStore} from "./media-delete-page-store";

const MediaTitle = styled.b`
    font-size: 1.2rem;
`;

/**
 * メディア削除ページのコンポーネント。
 * ユーザーがメディアを削除するためのインターフェースを提供します。
 * メディア削除の確認と操作を行い、成功またはエラーメッセージを表示します。
 */
export const MediaDeletePage: React.FC<{pageStore: MediaDeletePageStore}> = observer(
    ({pageStore}) => {
        const adminApi = useAdminApi();
        const activeMediaInfo = useActiveMediaStore((state) => state.activeMediaInfo);
        const mediaId = useMemo(() => activeMediaInfo.mediaId, [activeMediaInfo]);
        const mediaName = useMemo(() => activeMediaInfo.mediaName, [activeMediaInfo]);
        const [isOpen, setIsOpen] = useState<boolean>(false);
        const [inputValue, setInputValue] = useState<string>("");
        const [isDisabled, setIsDisabled] = useState<boolean>(true);
        const toasterRef = useRef<Toaster>(null);

        const navigate = useNavigate();

        /**
         * メディア削除APIリクエストを実行し、結果に応じてトーストメッセージを表示します。
         * エラー発生時にはエラーメッセージをトーストに表示します。
         */
        const initRequest = useCallback(async () => {
            try {
                const result = await adminApi.deleteMedia({mediaId});
                if (result) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "メディアを削除しました。",
                        intent: Intent.SUCCESS,
                    });
                    navigate("/admin/media-list");
                }
            } catch (e) {
                console.error("メディア削除にエラーが発生しました:", e);
            }
        }, [adminApi, mediaId, navigate]);

        /**
         * ダイアログを開く処理。
         */
        const handleOpen = useCallback(() => setIsOpen(true), []);

        /**
         * ダイアログを閉じ、入力値をリセットする処理。
         */
        const handleClose = useCallback(() => {
            setIsOpen(false);
            setInputValue("");
            setIsDisabled(true);
        }, []);

        /**
         * メディアを削除する処理。
         * メディア削除APIリクエストを実行し、その後ダイアログを閉じます。
         */
        const handleMediaDelete = useCallback(() => {
            initRequest();
            handleClose();
        }, [initRequest, handleClose]);

        /**
         * ユーザーの入力値を監視し、入力が正しい場合に削除ボタンを有効化します。
         * @param {React.ChangeEvent<HTMLInputElement>} event - 入力イベント
         */
        const handleInputChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                setInputValue(value);
                setIsDisabled(value !== mediaName);
            },
            [mediaName],
        );

        return (
            <AppLayout
                breadcrumb={
                    <AdminBreadcrumb factory={(builder) => builder.mediaDelete().build()} />
                }
            >
                <p data-testid="media-delete-page-heading">
                    メディア<MediaTitle>{mediaName}</MediaTitle>を削除します。
                </p>
                <p>この操作を行うと、関連するすべての情報が失われます。</p>
                <Button type="button" text={"削除する"} onClick={handleOpen} large={true} />
                <Dialog isOpen={isOpen} onClose={handleClose} title="本当に削除しますか？">
                    <DialogTextBox>
                        <div>
                            <p>削除する場合は下記に{mediaName}と入力してください</p>
                            <InputGroup
                                placeholder={mediaName}
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <DialogBox
                                onConfirm={handleMediaDelete}
                                onCancel={handleClose}
                                isDisabled={isDisabled}
                                confirmText="削除"
                                cancelText="キャンセル"
                            />
                        </div>
                    </DialogTextBox>
                </Dialog>
                <Toaster ref={toasterRef} position={Position.TOP} />
            </AppLayout>
        );
    },
);
