import {Button, FormGroup, InputGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import {observer} from "mobx-react";
import React, {useCallback, useEffect, useState} from "react";

import {useAdminApi} from "../../../api/api-context";
import {PasswordChangeInput} from "../../../api/values/password-change-input";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {PasswordChangePageStore} from "./password-change-page-store";

const toaster = Toaster.create({
    position: Position.TOP,
});

export const PasswordChangePage: React.FC<{pageStore: PasswordChangePageStore}> = observer(
    ({pageStore}) => {
        const [currentPassword, setCurrentPassword] = useState("");
        const [newPassword, setNewPassword] = useState("");
        const [confirmPassword, setConfirmPassword] = useState("");
        const [newPasswordError, setNewPasswordError] = useState("");
        const [currentPasswordError, setCurrentPasswordError] = useState("");
        const [confirmPasswordError, setConfirmPasswordError] = useState("");
        const [isFormValid, setIsFormValid] = useState(false);

        const adminApi = useAdminApi();

        const validateNewPassword = useCallback(() => {
            if (newPassword.length < 8) {
                setNewPasswordError("パスワードは8文字以上である必要があります");
                return true;
            } else {
                setNewPasswordError("");
                return false;
            }
        }, [newPassword]);

        const validateCurrentPassword = useCallback(() => {
            if (currentPassword === "") {
                setCurrentPasswordError("入力が必須です");
                return true;
            } else {
                setCurrentPasswordError("");
                return false;
            }
        }, [currentPassword]);

        const validateConfirmPassword = useCallback(() => {
            if (newPassword !== confirmPassword) {
                setConfirmPasswordError("パスワードが不一致です");
                return true;
            } else {
                setConfirmPasswordError("");
                return false;
            }
        }, [newPassword, confirmPassword]);

        const handlePasswordChange = useCallback(async () => {
            const currentPasswordHasError = validateCurrentPassword();
            const newPasswordHasError = validateNewPassword();
            const confirmPasswordHasError = validateConfirmPassword();

            if (currentPasswordHasError || newPasswordHasError || confirmPasswordHasError) return;

            try {
                const response = await adminApi.putPasswordChange(
                    new PasswordChangeInput({
                        password: currentPassword,
                        newPassword: newPassword,
                    }),
                );

                if (response) {
                    toaster.show({
                        message: "パスワードが正常に変更されました",
                        intent: Intent.SUCCESS,
                    });
                    setCurrentPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                    setNewPasswordError("");
                    setConfirmPasswordError("");
                    setCurrentPasswordError("");
                }
            } catch (error) {
                console.error("パスワードの変更中にエラーが発生しました", error);
                toaster.show({
                    message: "パスワードの変更に失敗しました",
                    intent: Intent.DANGER,
                });
            }
        }, [
            validateCurrentPassword,
            validateNewPassword,
            validateConfirmPassword,
            adminApi,
            currentPassword,
            newPassword,
        ]);

        const handleNewPasswordBlur = useCallback(() => {
            validateNewPassword();
        }, [validateNewPassword]);

        const handleConfirmPasswordBlur = useCallback(() => {
            validateConfirmPassword();
        }, [validateConfirmPassword]);

        const handleCurrentPasswordBlur = useCallback(() => {
            validateCurrentPassword();
        }, [validateCurrentPassword]);

        useEffect(() => {
            // フォームの全体的なバリデーション
            const isCurrentPasswordValid = currentPassword !== "" && !currentPasswordError;
            const isNewPasswordValid = newPassword.length >= 8 && !newPasswordError;
            const isConfirmPasswordValid = newPassword === confirmPassword && !confirmPasswordError;

            setIsFormValid(isCurrentPasswordValid && isNewPasswordValid && isConfirmPasswordValid);
        }, [
            currentPassword,
            newPassword,
            confirmPassword,
            currentPasswordError,
            newPasswordError,
            confirmPasswordError,
        ]);

        return (
            <AppLayout
                breadcrumb={
                    <AdminBreadcrumb factory={(builder) => builder.passwordChange().build()} />
                }
            >
                <FormGroup
                    label="現在のパスワード"
                    labelFor="current-password"
                    intent={currentPasswordError ? Intent.DANGER : undefined}
                    helperText={currentPasswordError}
                >
                    <InputGroup
                        id="current-password"
                        placeholder="現在のパスワードを入力してください"
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        onBlur={handleCurrentPasswordBlur}
                    />
                </FormGroup>
                <FormGroup
                    label="新しいパスワード"
                    labelFor="new-password"
                    intent={newPasswordError ? Intent.DANGER : undefined}
                    helperText={newPasswordError}
                >
                    <InputGroup
                        id="new-password"
                        placeholder="新しいパスワードを入力してください"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        onBlur={handleNewPasswordBlur}
                    />
                </FormGroup>
                <FormGroup
                    label="新しいパスワード（確認）"
                    labelFor="confirm-password"
                    intent={confirmPasswordError ? Intent.DANGER : undefined}
                    helperText={confirmPasswordError}
                >
                    <InputGroup
                        id="confirm-password"
                        placeholder="新しいパスワードを再入力してください"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onBlur={handleConfirmPasswordBlur}
                    />
                </FormGroup>
                <Button
                    intent="primary"
                    text="変更する"
                    onClick={handlePasswordChange}
                    disabled={!isFormValid}
                />
            </AppLayout>
        );
    },
);
