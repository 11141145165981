import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("ProfileOutput")
export class ProfileOutput {
    @JsonProperty("name", String, true)
    public name: string = "";

    @JsonProperty("email", String, true)
    public email: string = "";

    constructor(init?: Partial<ProfileOutput>) {
        Object.assign(this, init);
    }
}
