import {Button, Callout, FormGroup, Intent} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";
import {useEffect} from "react";

import {DocumentTypeEnum} from "../../../api/values/document-type";
import {useAquaEditor} from "../../../editor/use-aqua-editor";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {MomentFormats} from "../../../utils/moment-util";
import {DateTimeText} from "../../atoms/datetime-text/datetime-text";
import {AomEditor} from "../../molecules/aom-editor/aom-editor";
import {AquaEditorStatus} from "../../molecules/aqua-editor-status/aqua-editor-status";
import {ConfirmDialog} from "../../molecules/confirm-dialog/confirm-dialog";
import {LabeledCard} from "../../molecules/labeled-card/labeled-card";
import {UnloadWarning} from "../../molecules/unload-warning/unload-warning";
import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";
import {ValidatedTextArea} from "../../molecules/validated-text-area/validated-text-area";
import {DocumentRevisionUsers} from "../../organisms/document-revision-users/document-revision-users";
import {DocumentSettingDrawer} from "../../organisms/document-setting-drawer/document-setting-drawer";
import {DocumentTitleInput} from "../../organisms/document-title-input/document-title-input";
import {usePageChangingContext} from "../../organisms/page-changing-context/page-changing-context";
import {PublishDrawer} from "../../organisms/publish-drawer/publish-drawer";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {ArticleGeneralProperties} from "./article-general-properties";
import {ArticleHtmlProperties} from "./article-html-properties";
import {CopyDocumentRevisionDialog} from "./copy-document-revision-dialog";
import {DocumentEditBreadcrumb} from "./document-edit-breadcrumb";
import {DocumentEditButtons} from "./document-edit-buttons";
import {
    DocumentEditColumns,
    DocumentEditLeftColumn,
    DocumentEditRightColumn,
} from "./document-edit-columns";
import {DocumentEditPageStore} from "./document-edit-page-store";
import {DocumentInfoPanel} from "./document-info-panel";
import {RevisionListPanel} from "./revision-list-panel";

export const DocumentEditPage: React.FC<{pageStore: DocumentEditPageStore}> = observer((props) => {
    const pageChangingContext = usePageChangingContext();
    const activeMediaInfo = useActiveMediaStore((state) => state.activeMediaInfo);
    const activeMediaId = React.useMemo(() => activeMediaInfo.mediaId, [activeMediaInfo]);
    const activeMediaUrl = React.useMemo(() => activeMediaInfo.mediaUrl, [activeMediaInfo]);

    useEffect(() => {
        return pageChangingContext.listen(() => {
            props.pageStore.leaveEditing();
        });
    }, [pageChangingContext, props.pageStore]);

    const {aquaEditor, aquaEditorState} = useAquaEditor(activeMediaId, activeMediaUrl);

    return (
        <AppLayout
            breadcrumb={
                <DocumentEditBreadcrumb
                    documentType={props.pageStore.documentType}
                    documentId={props.pageStore.documentId}
                    documentRevisionId={
                        props.pageStore.isFromDocument
                            ? undefined
                            : props.pageStore.documentRevisionId
                    }
                />
            }
            buttons={
                <DocumentEditButtons pageStore={props.pageStore} siteUrl={aquaEditor?.siteUrl} />
            }
        >
            <UnloadWarning enabled={props.pageStore.isDirty} />
            {aquaEditor && (
                <PublishDrawer
                    store={props.pageStore.publishDrawerStore}
                    aquaEditor={aquaEditor}
                    onPublish={props.pageStore.handlePublished}
                />
            )}
            <DocumentSettingDrawer store={props.pageStore.documentSettingStore} />

            <ConfirmDialog
                title="下書きの削除"
                buttonLabel="削除"
                isOpen={props.pageStore.deleteDocumentRevisionStore.isOpen}
                onClose={props.pageStore.deleteDocumentRevisionStore.close}
                onAction={props.pageStore.deleteDocumentRevisionStore.delete}
            >
                下書きを削除します。
            </ConfirmDialog>

            <CopyDocumentRevisionDialog store={props.pageStore.copyDocumentRevisionStore} />

            <ConfirmDialog
                title="ドキュメントの削除"
                buttonLabel="削除"
                isOpen={props.pageStore.deleteDocumentStore.isOpen}
                onClose={props.pageStore.deleteDocumentStore.close}
                onAction={props.pageStore.deleteDocumentStore.delete}
            >
                ドキュメントを削除します。
            </ConfirmDialog>

            <ConfirmDialog
                title="ドキュメント非公開"
                buttonLabel="設定"
                isOpen={props.pageStore.unpublishDocumentStore.isOpen}
                onClose={props.pageStore.unpublishDocumentStore.close}
                onAction={props.pageStore.unpublishDocumentStore.unpublish}
            >
                ドキュメントを非公開にします。
            </ConfirmDialog>

            {props.pageStore.isNoSlugCalloutShown && (
                <Callout intent={Intent.WARNING}>
                    スラッグが設定されていません。公開にはスラッグが必要です。
                    <Button onClick={props.pageStore.startDocumentSetting}>設定する</Button>
                </Callout>
            )}

            <DocumentEditColumns>
                <DocumentEditLeftColumn>
                    {!props.pageStore.isFromDocument && (
                        <ValidatedInputText
                            maxLength={50}
                            placeholder="下書き名"
                            value={props.pageStore.revisionName}
                            validationResult={props.pageStore.revisionNameValidationResult}
                            onChangeValue={props.pageStore.setRevisionName}
                            style={{width: "200px", marginBottom: "5px"}}
                        />
                    )}
                    <div style={{marginBottom: "10px"}}>
                        <DocumentTitleInput
                            value={props.pageStore.title}
                            validationResult={props.pageStore.titleValidationResult}
                            onChangeValue={props.pageStore.setTitle}
                        />
                    </div>

                    <div style={{marginBottom: "30px"}}>
                        {!aquaEditor ? (
                            <AquaEditorStatus aquaEditorState={aquaEditorState} />
                        ) : (
                            <AomEditor
                                ref={props.pageStore.aomEditorRef}
                                aquaEditor={aquaEditor}
                                defaultNodeJson={props.pageStore.contentJson}
                                onReady={props.pageStore.setEditorInitialized}
                                onChange={props.pageStore.setContentDirty}
                                onSave={props.pageStore.saveFromEditor}
                                documentType={props.pageStore.documentType}
                                jsonMode={props.pageStore.isJsonMode}
                            />
                        )}
                    </div>

                    {(props.pageStore.documentType === DocumentTypeEnum.ARTICLE ||
                        props.pageStore.documentType === DocumentTypeEnum.PERSON) && (
                        <ArticleHtmlProperties store={props.pageStore.articleHtmlPropertiesStore} />
                    )}

                    <LabeledCard label="メモ">
                        <ValidatedTextArea
                            value={props.pageStore.note}
                            validationResult={props.pageStore.noteValidationResult}
                            onChangeValue={props.pageStore.setNote}
                            rows={5}
                        />
                    </LabeledCard>
                </DocumentEditLeftColumn>
                <DocumentEditRightColumn>
                    {props.pageStore.isFromDocument && (
                        <>
                            <LabeledCard label="公開情報">
                                <DocumentInfoPanel pageStore={props.pageStore} />
                            </LabeledCard>

                            <LabeledCard label="作業中の下書き">
                                <RevisionListPanel
                                    store={props.pageStore.revisionListPanelStore}
                                    isWorking={true}
                                />
                            </LabeledCard>

                            <LabeledCard label="公開済みの下書き">
                                <RevisionListPanel
                                    store={props.pageStore.revisionListPanelStore}
                                    isWorking={false}
                                />
                            </LabeledCard>
                        </>
                    )}

                    {!props.pageStore.isFromDocument && (
                        <LabeledCard label="下書き情報">
                            <FormGroup label="最終更新日時">
                                <DateTimeText
                                    time={props.pageStore.updatedAt}
                                    format={MomentFormats.datetimeMinute}
                                />
                                &nbsp;({props.pageStore.updatedUserName || ""})
                            </FormGroup>
                        </LabeledCard>
                    )}

                    {(props.pageStore.documentType === DocumentTypeEnum.ARTICLE ||
                        props.pageStore.documentType === DocumentTypeEnum.PERSON) && (
                        <ArticleGeneralProperties
                            pageStore={props.pageStore}
                            aquaEditor={aquaEditor}
                        />
                    )}

                    {!props.pageStore.isFromDocument && (
                        <LabeledCard label="担当者">
                            <DocumentRevisionUsers
                                names={props.pageStore.userNames}
                                onChange={props.pageStore.setUserNames}
                            />
                        </LabeledCard>
                    )}
                </DocumentEditRightColumn>
            </DocumentEditColumns>
        </AppLayout>
    );
});
