import {Button, Menu, MenuItem, PopoverPosition} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {DocumentProtectionStatus} from "../../../api/values/document-protection-status";
import {DocumentType, DocumentTypeEnum} from "../../../api/values/document-type";
import {Permission} from "../../../stores/authentication-store";
import {useAuthenticationStore} from "../../../stores/store-hooks";
import {ButtonList} from "../../molecules/button-list/button-list";
import {PopoverButton} from "../../molecules/popover-button/popover-button";
import {PopoverCreateButton} from "../../molecules/popover-create-button/popover-create-button";
import {DocumentEditPageStore} from "./document-edit-page-store";
import {PreviewPanel} from "./preview-panel";

const DocumentEditButtonsForRevision: React.FC<{
    pageStore: DocumentEditPageStore;
    siteUrl?: string;
}> = observer(({pageStore, siteUrl}) => {
    const authenticationStore = useAuthenticationStore();
    const mediaId = pageStore.mediaId;

    const isDocumentTypeViewable =
        pageStore.documentType === DocumentTypeEnum.ARTICLE ||
        pageStore.documentType === DocumentTypeEnum.PERSON;
    const hasEditResourcesPermission = authenticationStore.hasEditGeneralResourcesPermission;
    const hasManageSystemPermission = authenticationStore.hasManageSystemPermission;

    const showSave = authenticationStore.hasAllUsersOrLimitedUserPermissionExist(
        Permission.WRITE_DOCUMENTS,
        Permission.WRITE_ASSIGNED_DOCUMENTS,
        pageStore.userNames,
    );

    return (
        <ButtonList>
            {showSave && (
                <Button
                    disabled={!pageStore.canSaveExisting}
                    loading={pageStore.isSaving}
                    onClick={pageStore.saveToExisting}
                >
                    保存
                </Button>
            )}

            {isDocumentTypeViewable && showSave && siteUrl && (
                <PopoverButton
                    label="共有"
                    position={PopoverPosition.AUTO_END}
                    isOpen={pageStore.previewPanelStore.isOpen}
                    onInteraction={(b) => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        pageStore.previewPanelStore.openOrClose(
                            b,
                            mediaId,
                            pageStore.documentRevisionId,
                        );
                    }}
                >
                    <PreviewPanel store={pageStore.previewPanelStore} siteUrl={siteUrl} />
                </PopoverButton>
            )}

            {hasEditResourcesPermission && (
                <Button loading={pageStore.isStartPublishing} onClick={pageStore.startPublish}>
                    この下書きを公開
                </Button>
            )}

            {hasEditResourcesPermission && (
                <PopoverButton icon="menu">
                    <Menu>
                        {hasManageSystemPermission && (
                            <MenuItem
                                text="Aom JSONモード(開発用)"
                                onClick={() => pageStore.setJsonMode(true)}
                            />
                        )}

                        <MenuItem
                            text="下書きを複製"
                            onClick={pageStore.startCopyDocumentRevision}
                        />

                        <MenuItem
                            text="下書きを削除"
                            onClick={pageStore.startDeleteDocumentRevision}
                            disabled={!pageStore.canDeleteDocumentRevision}
                        />
                    </Menu>
                </PopoverButton>
            )}
        </ButtonList>
    );
});

const DocumentEditButtonsForDocument: React.FC<{
    pageStore: DocumentEditPageStore;
    siteUrl?: string;
}> = observer(({pageStore, siteUrl}) => {
    const authenticationStore = useAuthenticationStore();

    const isDocumentTypeViewable =
        pageStore.documentType === DocumentType.ARTICLE ||
        pageStore.documentType === DocumentType.PERSON;
    const hasEditGeneralResourcesPermission = authenticationStore.hasEditGeneralResourcesPermission;
    const hasManageSystemPermission = authenticationStore.hasManageSystemPermission;
    const hasManageDocumentsPermission = authenticationStore.hasManageDocumentsPermission;

    return (
        <ButtonList>
            {hasManageDocumentsPermission && (
                <PopoverCreateButton
                    label="名前を付けて下書きを作成"
                    placeholder="下書き名"
                    onCreate={pageStore.saveNew}
                    closeAfterCreate={false}
                    disabled={!pageStore.canSaveNew}
                />
            )}

            {isDocumentTypeViewable &&
                siteUrl &&
                pageStore.protectionStatus === DocumentProtectionStatus.VISIBLE && (
                    <Button
                        disabled={!pageStore.canShowPage}
                        onClick={() => pageStore.showPage(siteUrl)}
                    >
                        表示
                    </Button>
                )}

            {authenticationStore && hasEditGeneralResourcesPermission && (
                <Button
                    disabled={!pageStore.isDirty}
                    loading={pageStore.isStartPublishing}
                    onClick={pageStore.startQuickPublish}
                >
                    クイック公開
                </Button>
            )}

            {authenticationStore && hasEditGeneralResourcesPermission && (
                <PopoverButton icon="menu">
                    <Menu>
                        {hasManageSystemPermission && (
                            <>
                                <MenuItem
                                    text="Aom JSONモード(開発用)"
                                    onClick={() => pageStore.setJsonMode(true)}
                                />

                                <MenuItem
                                    text="ドキュメントを最適化"
                                    onClick={pageStore.optimize}
                                />
                            </>
                        )}

                        <MenuItem
                            text="ドキュメントを非公開化"
                            onClick={pageStore.startUnpublish}
                            disabled={!pageStore.canUnpublish}
                        />

                        {!pageStore.archived && (
                            <MenuItem
                                text="ドキュメントをアーカイブ"
                                onClick={pageStore.archive}
                                disabled={
                                    pageStore.protectionStatus === DocumentProtectionStatus.VISIBLE
                                }
                            />
                        )}

                        {pageStore.archived && (
                            <MenuItem
                                text="ドキュメントをアーカイブ解除"
                                onClick={pageStore.unarchive}
                            />
                        )}

                        <MenuItem
                            text="ドキュメントを削除"
                            onClick={pageStore.startDeleteDocument}
                            disabled={
                                pageStore.protectionStatus === DocumentProtectionStatus.VISIBLE
                            }
                        />
                    </Menu>
                </PopoverButton>
            )}
        </ButtonList>
    );
});

export const DocumentEditButtons: React.FC<{
    pageStore: DocumentEditPageStore;
    siteUrl?: string;
}> = observer(({pageStore, siteUrl}) =>
    pageStore.isFromDocument ? (
        <DocumentEditButtonsForDocument pageStore={pageStore} siteUrl={siteUrl} />
    ) : (
        <DocumentEditButtonsForRevision pageStore={pageStore} siteUrl={siteUrl} />
    ),
);
