import {Button, FormGroup, HTMLSelect} from "@blueprintjs/core";
import {Classes, Dialog} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";

import {UserRole} from "../../../api/values/user-role-type";
import {DialogBox, DialogTextBox} from "../../molecules/media-confirm-dialog/DialogComponents";
import {SubmitGroup} from "../../molecules/submit-group/submit-group";
import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {User, UserEditPageStore} from "./user-edit-page-store";
import {UserNameInputWithDropdown} from "./user-name-input-with-dropdown";

function nameHelperText(pageStore: UserEditPageStore): string | undefined {
    if (pageStore.isExistingUser && pageStore.isNew) {
        return "";
    }
    const isValidInput = /^[a-z0-9]*$/.test(pageStore.editingName);

    if (!isValidInput) {
        return "a~z,0-9までの半角の小文字の英字、数字のみ使用できます";
    } else if (pageStore.isNameConflicted) {
        return "すでに利用されています";
    }

    return undefined;
}

@observer
export class UserEditPage extends React.Component<
    {
        pageStore: UserEditPageStore;
    },
    {}
> {
    state = {
        isWarningModalOpen: false,
        pendingRole: null as UserRole | null, // 一時的に保持するロール
    };

    handleRoleChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const newRole = UserRole.fromName(evt.target.value) || UserRole.WRITER;

        // 新規ユーザー追加の場合はモーダルを表示しない
        if (this.props.pageStore.isNew) {
            this.props.pageStore.setRole(newRole);
            return;
        }

        // 現在のロールが ADMIN で、新しいロールが異なる場合、モーダルを表示
        if (this.props.pageStore.role.name === "ADMIN" && newRole.name !== "ADMIN") {
            this.setState({isWarningModalOpen: true, pendingRole: newRole});
        } else {
            this.props.pageStore.setRole(newRole);
        }
    };

    handleConfirmRoleChange = () => {
        this.setState({isWarningModalOpen: false});
        if (this.state.pendingRole) {
            this.props.pageStore.setRole(this.state.pendingRole);
        }
    };

    render() {
        const {pageStore} = this.props;

        const handleSelectUser = (user: User | null) => {
            if (user) {
                pageStore.setExistingUser(user);
            }
        };

        return (
            <AppLayout
                breadcrumb={
                    <AdminBreadcrumb
                        factory={(builder) =>
                            builder
                                .userList()
                                .custom(
                                    this.props.pageStore.isNew
                                        ? "ユーザー追加"
                                        : "ユーザー情報変更",
                                )
                                .build()
                        }
                    />
                }
            >
                <div style={{marginTop: "20px", width: "400px"}}>
                    <SubmitGroup
                        canSubmit={this.props.pageStore.canSave}
                        onSubmit={this.props.pageStore.save}
                    >
                        {pageStore.isNew ? (
                            <UserNameInputWithDropdown
                                value={pageStore.editingName}
                                onChangeValue={pageStore.setName}
                                onSelectUser={handleSelectUser}
                                userList={pageStore.userList}
                                isLoading={pageStore.isLoadingUserList}
                                onFocus={pageStore.fetchUserList}
                                validationResult={pageStore.nameValidationResult}
                                helperText={nameHelperText(pageStore)}
                                disabled={!pageStore.canEdit}
                                isNew={pageStore.isNew}
                            />
                        ) : (
                            <ValidatedInputText
                                label="ユーザー名"
                                type="text"
                                value={this.props.pageStore.editingName}
                                validationResult={this.props.pageStore.nameValidationResult}
                                onChangeValue={this.props.pageStore.setName}
                                validationInProgress={
                                    this.props.pageStore.isNameValidationInProgress
                                }
                                helperText={nameHelperText(this.props.pageStore)}
                                disabled={!this.props.pageStore.canEdit}
                            />
                        )}

                        <ValidatedInputText
                            label="メールアドレス"
                            type="text"
                            placeholder="aqua@example.com"
                            value={this.props.pageStore.email}
                            validationResult={this.props.pageStore.emailValidationResult}
                            onChangeValue={this.props.pageStore.setEmail}
                            disabled={!this.props.pageStore.canEdit || pageStore.isExistingUser}
                        />

                        {!this.props.pageStore.isExistingUser && this.props.pageStore.isNew && (
                            <ValidatedInputText
                                label="パスワード"
                                type="password"
                                value={this.props.pageStore.password}
                                validationResult={this.props.pageStore.passwordValidationResult}
                                onChangeValue={this.props.pageStore.setPassword}
                                disabled={pageStore.isExistingUser}
                            />
                        )}

                        <FormGroup label="ロール">
                            <HTMLSelect
                                value={this.props.pageStore.role.name}
                                onChange={this.handleRoleChange}
                                disabled={!this.props.pageStore.canEdit}
                            >
                                {this.props.pageStore.availableRoles.map((role) => (
                                    <option key={role.name} value={role.name}>
                                        {role.displayName}
                                    </option>
                                ))}
                            </HTMLSelect>
                        </FormGroup>

                        {!this.props.pageStore.isNew && (
                            <FormGroup label="アカウント状態">
                                <HTMLSelect
                                    value={this.props.pageStore.enabled ? "true" : "false"}
                                    onChange={(evt) =>
                                        this.props.pageStore.setEnabled(evt.target.value === "true")
                                    }
                                    disabled={!this.props.pageStore.canEdit}
                                >
                                    <option value="true">有効</option>
                                    <option value="false">無効</option>
                                </HTMLSelect>
                            </FormGroup>
                        )}

                        <div style={{marginBottom: "20px"}} />

                        <Button
                            type="submit"
                            text={this.props.pageStore.isNew ? "追加" : "変更"}
                            disabled={
                                !this.props.pageStore.canSave || !this.props.pageStore.canEdit
                            }
                            loading={this.props.pageStore.isSavingInProgress}
                            fill={true}
                        />
                    </SubmitGroup>
                </div>
                {/* モーダル */}
                <Dialog
                    isOpen={this.state.isWarningModalOpen}
                    onClose={() => this.setState({isWarningModalOpen: false})}
                    className={Classes.DIALOG}
                    title="権限変更の確認"
                >
                    <DialogTextBox>
                        <div className={Classes.DIALOG_BODY}>
                            <p>
                                システム管理者から他の権限に変更しようとしています。続行してもよろしいですか？
                            </p>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <DialogBox
                                onConfirm={this.handleConfirmRoleChange}
                                onCancel={() => this.setState({isWarningModalOpen: false})}
                                confirmText="続行"
                                cancelText="キャンセル"
                            />
                        </div>
                    </DialogTextBox>
                </Dialog>
            </AppLayout>
        );
    }
}
