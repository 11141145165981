import {Button} from "@blueprintjs/core";
import {observer} from "mobx-react";
import * as React from "react";
import {useParams} from "react-router-dom";

import {useAquaEditor} from "../../../editor/use-aqua-editor";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {AquaEditorStatus} from "../../molecules/aqua-editor-status/aqua-editor-status";
import {LabeledCard} from "../../molecules/labeled-card/labeled-card";
import ProgressPanel from "../../molecules/progress-panel/progress-panel";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {FileWithButton} from "./file-with-button";
import {ImportControlList} from "./import-control-list";
import {ImportPageStore} from "./import-page-store";

export const ImportPage: React.FC<{pageStore: ImportPageStore}> = observer(({pageStore}) => {
    const activeMediaInfo = useActiveMediaStore((state) => state.activeMediaInfo);
    const activeMediaId = React.useMemo(() => activeMediaInfo.mediaId, [activeMediaInfo]);
    const activeMediaUrl = React.useMemo(() => activeMediaInfo.mediaUrl, [activeMediaInfo]);
    const {aquaEditor, aquaEditorState} = useAquaEditor(activeMediaId, activeMediaUrl);
    const params = useParams();
    const {mediaId} = params as {mediaId: string};

    return (
        <AppLayout>
            <div style={{marginBottom: "20px"}}>
                <AquaEditorStatus aquaEditorState={aquaEditorState} />
            </div>

            <ProgressPanel
                inProgress={pageStore.isInProgress}
                progressMax={pageStore.progressMax}
                progressCurrent={pageStore.progressCurrent}
            >
                <LabeledCard label="ユーザーエクスポート / インポート">
                    <ImportControlList>
                        <Button onClick={() => pageStore.exportUsers()}>エクスポート</Button>
                        <FileWithButton label="インポート" onAction={pageStore.importUsers} />
                    </ImportControlList>
                </LabeledCard>

                {/* 機能を廃止したため、以下コメントアウト
                <LabeledCard label="ドキュメントエクスポート / インポート">
                    <ImportControlList>
                        <Button onClick={() => pageStore.exportDocuments()}>エクスポート</Button>
                        <FileWithButton
                            label="インポート"
                            onAction={(blob) =>
                                aquaEditor && pageStore.importDocuments(aquaEditor, blob, mediaId)
                            }
                        />
                        <Checkbox
                            checked={pageStore.importFreshOnly}
                            onChange={handleChangeBoolean((b) => pageStore.setImportFreshOnly(b))}
                        >
                            新しいドキュメントのみ
                        </Checkbox>
                    </ImportControlList>
                </LabeledCard>

                <LabeledCard label="グループエクスポート / インポート">
                    <ImportControlList>
                        <Button onClick={() => pageStore.exportGroups()}>エクスポート</Button>
                        <FileWithButton label="インポート" onAction={pageStore.importGroups} />
                    </ImportControlList>
                </LabeledCard> */}

                <LabeledCard label="カテゴリエクスポート / インポート">
                    <ImportControlList>
                        <Button onClick={() => pageStore.exportCategoryGroups(mediaId)}>
                            エクスポート
                        </Button>
                        <FileWithButton
                            label="インポート"
                            onAction={(blob) => pageStore.importCategoryGroups(mediaId, blob)}
                        />
                    </ImportControlList>
                </LabeledCard>

                <LabeledCard label="キャッシュ更新">
                    <ImportControlList>
                        <Button
                            onClick={() => aquaEditor && pageStore.updateHTML(aquaEditor, mediaId)}
                        >
                            HTMLキャッシュ更新
                        </Button>
                        <Button onClick={() => pageStore.updateSearchCache(mediaId)}>
                            検索キャッシュ更新
                        </Button>
                    </ImportControlList>
                </LabeledCard>
            </ProgressPanel>
        </AppLayout>
    );
});
