import {ControlGroup, FormGroup, HTMLSelect} from "@blueprintjs/core";
import {TimePrecision} from "@blueprintjs/datetime";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import * as React from "react";

import {DocumentContainsPr} from "../../../api/values/document-contains-pr";
import {DocumentPublishScope} from "../../../api/values/document-publish-scope";
import {IAquaEditor} from "../../../editor/aqua-editor";
import {handleChangeString} from "../../../utils/handle-change";
import {LabeledCard} from "../../molecules/labeled-card/labeled-card";
import {MomentDateInput} from "../../molecules/moment-date-input/moment-date-input";
import {UploadableImage} from "../../molecules/uploadable-image/uploadable-image";
import {ValidatedInputText} from "../../molecules/validated-input-text/validated-input-text";
import {CategoryListEditor} from "../../organisms/category-list-editor/category-list-editor";
import {SlugListEditor} from "../../organisms/slug-list-editor/slug-list-editor";
import {DocumentEditPageStore} from "./document-edit-page-store";

const StyledUploadableImage = styled(UploadableImage)`
    width: 200px;
    height: 150px;
`;

const StyledUploadableImagePerson = styled(UploadableImage)`
    width: 150px;
    height: 150px;
`;

@observer
export class ArticleGeneralProperties extends React.Component<{
    pageStore: DocumentEditPageStore;
    aquaEditor?: IAquaEditor;
}> {
    public render() {
        return (
            <>
                <LabeledCard label="日時情報">
                    <FormGroup label="公開日時（画面表示用）">
                        <ControlGroup>
                            <MomentDateInput
                                timePrecision={TimePrecision.MINUTE}
                                onChange={(d) => this.props.pageStore.setPublishedAt(d)}
                                value={this.props.pageStore.publishedAt.toDate()}
                            />
                        </ControlGroup>
                    </FormGroup>
                    <FormGroup label="修正日時（画面表示用）">
                        <ControlGroup>
                            <MomentDateInput
                                timePrecision={TimePrecision.MINUTE}
                                onChange={(d) => this.props.pageStore.setModifiedAt(d)}
                                value={this.props.pageStore.modifiedAt.toDate()}
                            />
                        </ControlGroup>
                    </FormGroup>
                </LabeledCard>

                <LabeledCard label="アイキャッチ画像">
                    <FormGroup label="画像">
                        <StyledUploadableImage
                            aquaEditor={this.props.aquaEditor}
                            imageUri={this.props.pageStore.featuredImageUri}
                            onUploaded={(uri) => this.props.pageStore.setFeaturedImageUri(uri)}
                            isEditable={true}
                        />
                    </FormGroup>

                    <FormGroup label="出典タイトル">
                        <ValidatedInputText
                            value={this.props.pageStore.featuredImageAuthorityTitle}
                            validationResult={
                                this.props.pageStore.featuredImageAuthorityTitleValidationResult
                            }
                            onChangeValue={this.props.pageStore.setFeaturedImageAuthorityTitle}
                        />
                    </FormGroup>

                    <FormGroup label="出典URL">
                        <ValidatedInputText
                            value={this.props.pageStore.featuredImageAuthorityUri}
                            validationResult={
                                this.props.pageStore.featuredImageAuthorityUriValidationResult
                            }
                            onChangeValue={this.props.pageStore.setFeaturedImageAuthorityUri}
                        />
                    </FormGroup>
                </LabeledCard>

                <LabeledCard label="関連ドキュメント">
                    <SlugListEditor
                        slugs={this.props.pageStore.relatedDocumentSlugs}
                        onChange={this.props.pageStore.setRelatedDocumentSlugs}
                    />
                </LabeledCard>

                <LabeledCard label="人物">
                    <FormGroup label="名前">
                        <ValidatedInputText
                            value={this.props.pageStore.personName}
                            validationResult={this.props.pageStore.personNameValidationResult}
                            onChangeValue={this.props.pageStore.setPersonName}
                        />
                    </FormGroup>
                    <FormGroup label="役割">
                        <ValidatedInputText
                            value={this.props.pageStore.personRole}
                            validationResult={this.props.pageStore.personRoleValidationResult}
                            onChangeValue={this.props.pageStore.setPersonRole}
                        />
                    </FormGroup>
                    <FormGroup label="画像">
                        <StyledUploadableImagePerson
                            aquaEditor={this.props.aquaEditor}
                            imageUri={this.props.pageStore.personImageUri}
                            onUploaded={(uri) => this.props.pageStore.setPersonImageUri(uri)}
                            isEditable={true}
                        />
                    </FormGroup>
                    <FormGroup label="参照スラッグ">
                        <ValidatedInputText
                            value={this.props.pageStore.personSlug}
                            validationResult={this.props.pageStore.personSlugValidationResult}
                            onChangeValue={this.props.pageStore.setPersonSlug}
                        />
                    </FormGroup>
                </LabeledCard>

                <LabeledCard label="カテゴリ">
                    <CategoryListEditor
                        slugs={this.props.pageStore.categorySlugs}
                        onChange={this.props.pageStore.setCategorySlugs}
                    />
                </LabeledCard>

                <LabeledCard label="セキュリティ">
                    <FormGroup label="公開範囲">
                        <ControlGroup>
                            <HTMLSelect
                                value={this.props.pageStore.publishScope}
                                onChange={handleChangeString((s) =>
                                    this.props.pageStore.setPublishScope(s),
                                )}
                            >
                                <option value={DocumentPublishScope.LIST}>全体</option>
                                <option value={DocumentPublishScope.GET}>ドキュメントのみ</option>
                            </HTMLSelect>
                        </ControlGroup>
                    </FormGroup>
                </LabeledCard>

                <LabeledCard label="プロモーション">
                    <FormGroup label="広告表示">
                        <ControlGroup>
                            <HTMLSelect
                                value={this.props.pageStore.containsPr}
                                onChange={handleChangeString((s) =>
                                    this.props.pageStore.setContainsPr(s),
                                )}
                            >
                                <option value={DocumentContainsPr.CONTAINS}>
                                    表示する(PR広告が含まれています)
                                </option>
                                <option value={DocumentContainsPr.NOT_CONTAINS}>表示しない</option>
                            </HTMLSelect>
                        </ControlGroup>
                    </FormGroup>
                </LabeledCard>
            </>
        );
    }
}
