import {makeObservable, observable} from "mobx";

import {AdminApi} from "../../../api/api";
import {Stores} from "../../../stores";
import {Permission} from "../../../stores/authentication-store";
import {PageStoreConstructorOptions} from "../../../utils/page-store";
import {PageRouter} from "../../../utils/route-util";

export class MediaListPageStore {
    @observable
    private adminApi: AdminApi;
    private stores: Stores;
    private pageRouter: PageRouter;

    constructor(options: PageStoreConstructorOptions) {
        makeObservable(this);

        this.adminApi = options.adminApi;
        this.stores = options.stores;
        this.pageRouter = options.pageRouter;
    }
    public async initialize({query}: any = {}) {
        // 全権限がアクセス可能
        this.stores.authenticationStore.checkAnyPermissionExists(
            Permission.READ_DOCUMENTS,
            Permission.READ_ASSIGNED_DOCUMENTS,
        );
    }
}
