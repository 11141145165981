import {JsonObject, JsonProperty} from "json2typescript";
import moment from "moment";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, DateTimeConverter, JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentPropertyRelatedResourceInputOutput} from "./document-property-related-resource-input-output";
import {DocumentType} from "./document-type";

@JsonObject("DocumentListItemOutput")
export class DocumentListItemOutput {
    @JsonProperty("id", BigIntIdConverter)
    public id: BigIntId = JsonPropertyEmpty;

    @JsonProperty()
    public documentType: DocumentType = JsonPropertyEmpty;

    @JsonProperty()
    public title: string = JsonPropertyEmpty;

    @JsonProperty()
    public slug: string = JsonPropertyEmpty;

    @JsonProperty("publishedAt", DateTimeConverter)
    public publishedAt: moment.Moment = JsonPropertyEmpty;

    @JsonProperty("modifiedAt", DateTimeConverter)
    public modifiedAt: moment.Moment = JsonPropertyEmpty;

    @JsonProperty()
    public archived: boolean = JsonPropertyEmpty;

    @JsonProperty("relatedResources", [DocumentPropertyRelatedResourceInputOutput])
    public relatedResources: DocumentPropertyRelatedResourceInputOutput[] = JsonPropertyEmpty;
}
