import {observer} from "mobx-react";
import * as React from "react";
import {createContext, useContext, useEffect, useState} from "react";

import {useAdminApi} from "../api/api-context";
import {useAuthenticationStore} from "../stores/store-hooks";
import {IAquaEditor} from "./aqua-editor";
import {loadAquaEditor} from "./load-aqua-editor";

export type AquaEditorData = {
    [mediaId: string]:
        | {state: "uninitialized"}
        | {state: "loading"}
        | {state: "loaded"; aquaEditor: IAquaEditor}
        | {state: "error"; error: Error};
};

export type AquaEditorState = AquaEditorData[string]["state"];

export interface AquaEditorContextValue {
    aquaEditorData: AquaEditorData;
    setAquaEditorData: (mediaId: string, data: AquaEditorData[string]) => void;
    loadEditor: (mediaId: string, mediaUrl: string) => void;
}

export const AquaEditorContext = createContext<AquaEditorContextValue | undefined>(undefined);

export const AquaEditorProvider: React.FC = observer(({children}) => {
    const adminApi = useAdminApi();
    const authenticationStore = useAuthenticationStore();
    const username = authenticationStore.username;

    const [aquaEditorData, setAquaEditorData] = useState<AquaEditorData>({});

    const setEditorData = (mediaId: string, data: AquaEditorData[string]) => {
        setAquaEditorData((prevData) => ({
            ...prevData,
            [mediaId]: data,
        }));
    };

    // editorの読み込み
    const loadEditor = (mediaId: string, mediaUrl: string) => {
        // "uninitialized"ではない場合、またはusernameがない場合は何もしない
        if (aquaEditorData[mediaId]?.state !== "uninitialized" || !username) return;

        const imagefrontTokenProvider = async () => {
            return (await authenticationStore.getOrRefreshToken())!;
        };

        // "loading"状態に変更
        setEditorData(mediaId, {state: "loading"});

        // aquaEditorをロードしてstateを"loaded"に変更
        loadAquaEditor({siteUrl: mediaUrl, adminApi, imagefrontTokenProvider, user: username})
            .then((aquaEditor) => setEditorData(mediaId, {state: "loaded", aquaEditor}))
            // エラーが発生した場合はstateを"error"に変更
            .catch((error) => {
                console.error(error);
                setEditorData(mediaId, {state: "error", error});
            });
    };

    return (
        <AquaEditorContext.Provider
            value={{
                aquaEditorData,
                setAquaEditorData: setEditorData,
                loadEditor,
            }}
        >
            {children}
        </AquaEditorContext.Provider>
    );
});

export function useAquaEditorContext(mediaId: string, mediaUrl: string) {
    const context = useContext(AquaEditorContext);
    const [firstFlag, setFirstFlag] = useState(true);

    if (!context) {
        throw new Error("AquaEditorProvider is not provided");
    }

    const {aquaEditorData, loadEditor, setAquaEditorData} = context;

    const editorData = aquaEditorData[mediaId];

    // mediaIdに対応するエディタデータが存在しない場合は初期化
    if (!editorData) {
        setAquaEditorData(mediaId, {state: "uninitialized"});
    } else {
        // エラーが発生している場合は再度ロード
        if (editorData?.state === "error" && firstFlag) {
            setFirstFlag(false);
            setAquaEditorData(mediaId, {state: "uninitialized"});
        }
        if (editorData?.state === "loaded") {
            // editorがない場合は再度ロード
            if (!editorData?.aquaEditor) {
                loadEditor(mediaId, mediaUrl);
            } else {
                // 現メディアURLと異なる場合は再度ロード
                if (editorData.aquaEditor.siteUrl !== mediaUrl) {
                    setAquaEditorData(mediaId, {state: "uninitialized"});
                }
            }
        }
    }

    useEffect(() => {
        // "uninitialized"の場合のみエディタをロード
        if (editorData?.state === "uninitialized") {
            loadEditor(mediaId, mediaUrl);
        } else if (editorData?.state === "error") {
            // エラーが発生している場合は再度ロード
            loadEditor(mediaId, mediaUrl);
        }
    }, [aquaEditorData]); // eslint-disable-line react-hooks/exhaustive-deps

    return {loadEditor, aquaEditorData, setAquaEditorData};
}
