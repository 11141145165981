import {FormGroup, InputGroup, Intent, Menu, MenuItem, Popover} from "@blueprintjs/core";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";

import {ValidationResult} from "../../../utils/validation/validation-result";

interface User {
    name: string;
    email: string;
}

interface UserNameInputWithDropdownProps {
    value: string;
    onChangeValue: (value: string) => void;
    onSelectUser: (user: User | null) => void;
    userList: User[];
    isLoading: boolean;
    onFocus: () => void;
    validationResult: ValidationResult;
    helperText?: string;
    disabled?: boolean;
    isNew: boolean;
}

export const UserNameInputWithDropdown: React.FC<UserNameInputWithDropdownProps> = ({
    value,
    onChangeValue,
    onSelectUser,
    userList,
    onFocus,
    validationResult,
    helperText,
    disabled,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setFilteredUsers(
            userList.filter((user) => user.name.toLowerCase().includes(value.toLowerCase())),
        );
    }, [value, userList]);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            onChangeValue(newValue);
            onSelectUser(null);
            setIsOpen(true);
        },
        [onChangeValue, onSelectUser],
    );

    const handleItemSelect = useCallback(
        (user: User) => {
            onChangeValue(user.name);
            onSelectUser(user);
            setIsOpen(false);
            inputRef.current?.focus();
        },

        [onChangeValue, onSelectUser],
    );

    const handleInputFocus = useCallback(() => {
        onFocus();
        setIsOpen(true);
    }, [onFocus]);

    const handleInputBlur = useCallback(() => {
        setTimeout(() => {
            if (document.activeElement !== inputRef.current) {
                setIsOpen(false);
            }
        }, 200);
    }, []);

    const intent = validationResult.isEmpty ? Intent.NONE : Intent.DANGER;

    return (
        <FormGroup label="ユーザー名" helperText={helperText} intent={intent}>
            <Popover
                isOpen={isOpen && filteredUsers.length > 0}
                minimal={true}
                position="bottom-left"
                autoFocus={false}
                content={
                    <Menu style={{maxHeight: "200px", overflowY: "auto"}}>
                        {filteredUsers.map((user) => (
                            <MenuItem
                                key={user.name}
                                text={user.name}
                                onClick={() => handleItemSelect(user)}
                            />
                        ))}
                    </Menu>
                }
            >
                <InputGroup
                    value={value}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    disabled={disabled}
                    placeholder="ユーザー名を入力"
                    inputRef={inputRef}
                />
            </Popover>
        </FormGroup>
    );
};
