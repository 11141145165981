import {Button, FormGroup, InputGroup, Intent, Spinner} from "@blueprintjs/core";
import React, {useCallback, useEffect, useReducer} from "react";

import {useAdminApi} from "../../../api/api-context";
import {ProfileChangeInput} from "../../../api/values/profile-page-input";
import {useStores} from "../../../stores/store-hooks";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";

type State = {
    displayName: string;
    email: string;
    errors: {
        displayName?: string;
        email?: string;
    };
    isLoading: boolean;
    isSubmitting: boolean;
};

type Action =
    | {type: "SET_DISPLAY_NAME"; payload: string}
    | {type: "SET_EMAIL"; payload: string}
    | {type: "SET_ERRORS"; payload: {displayName?: string; email?: string}}
    | {type: "SET_LOADING"; payload: boolean}
    | {type: "SET_SUBMITTING"; payload: boolean};

const initialState: State = {
    displayName: "",
    email: "",
    errors: {},
    isLoading: true,
    isSubmitting: false,
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_DISPLAY_NAME":
            return {...state, displayName: action.payload};
        case "SET_EMAIL":
            return {...state, email: action.payload};
        case "SET_ERRORS":
            return {...state, errors: action.payload};
        case "SET_LOADING":
            return {...state, isLoading: action.payload};
        case "SET_SUBMITTING":
            return {...state, isSubmitting: action.payload};
        default:
            return state;
    }
};

export const ProfilePage: React.FC = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {toastStore} = useStores();
    const adminApi = useAdminApi();

    const fetchUserData = useCallback(async () => {
        dispatch({type: "SET_LOADING", payload: true});
        try {
            const response = await adminApi.getProfileData(new ProfileChangeInput());

            // レスポンスが空の場合の処理
            if (!response || Object.keys(response).length === 0) {
                console.warn("APIレスポンスが空です。デフォルト値を使用します。");
                dispatch({type: "SET_DISPLAY_NAME", payload: ""});
                dispatch({type: "SET_EMAIL", payload: ""});
                return;
            }

            const {name, email} = response;
            dispatch({type: "SET_DISPLAY_NAME", payload: name || ""});
            dispatch({type: "SET_EMAIL", payload: email || ""});
        } catch (error) {
            console.error("ユーザー情報の取得に失敗しました。", error);
            toastStore?.showErrorToast("ユーザー情報の取得に失敗しました。");
        } finally {
            dispatch({type: "SET_LOADING", payload: false});
        }
    }, [adminApi, toastStore]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const handleDisplayNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: "SET_DISPLAY_NAME", payload: event.target.value});
    }, []);

    const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: "SET_EMAIL", payload: event.target.value});
    }, []);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = useCallback(async () => {
        const newErrors: {displayName?: string; email?: string} = {};

        if (!state.displayName) {
            newErrors.displayName = "表示名を入力してください。";
        }

        if (!state.email) {
            newErrors.email = "メールアドレスを入力してください。";
        } else if (!validateEmail(state.email)) {
            newErrors.email = "有効なメールアドレスを入力してください。";
        }

        dispatch({type: "SET_ERRORS", payload: newErrors});

        if (Object.keys(newErrors).length === 0) {
            dispatch({type: "SET_SUBMITTING", payload: true});
            try {
                const response = await adminApi.putProfileUpdate(
                    new ProfileChangeInput({
                        name: state.displayName,
                        email: state.email,
                    }),
                );

                if (response && Object.keys(response).length === 0) {
                    toastStore?.showSuccessToast("プロフィールが正常に更新されました。");
                } else {
                    toastStore?.showSuccessToast("プロフィールが正常に更新されました。");
                }
            } catch (error) {
                console.error("プロフィールの更新に失敗しました。", error);
                toastStore?.showErrorToast("プロフィールの更新に失敗しました。");
            } finally {
                dispatch({type: "SET_SUBMITTING", payload: false});
            }
        }
    }, [state.displayName, state.email, adminApi, toastStore]);

    if (state.isLoading) {
        return <Spinner />;
    }

    return (
        <AppLayout
            breadcrumb={<AdminBreadcrumb factory={(builder) => builder.profile().build()} />}
        >
            <div>
                <FormGroup
                    label="表示名"
                    labelFor="display-name-input"
                    helperText={state.errors.displayName}
                    intent={state.errors.displayName ? Intent.DANGER : Intent.NONE}
                >
                    <InputGroup
                        id="display-name-input"
                        placeholder="入力フォーム"
                        value={state.displayName}
                        onChange={handleDisplayNameChange}
                        intent={state.errors.displayName ? Intent.DANGER : Intent.NONE}
                    />
                </FormGroup>
                <FormGroup
                    label="メールアドレス"
                    labelFor="email-input"
                    helperText={state.errors.email}
                    intent={state.errors.email ? Intent.DANGER : Intent.NONE}
                >
                    <InputGroup
                        id="email-input"
                        placeholder="入力フォーム"
                        value={state.email}
                        onChange={handleEmailChange}
                        intent={state.errors.email ? Intent.DANGER : Intent.NONE}
                    />
                </FormGroup>
                <Button
                    intent="primary"
                    onClick={handleSubmit}
                    disabled={state.isSubmitting}
                    loading={state.isSubmitting}
                >
                    変更する
                </Button>
            </div>
        </AppLayout>
    );
};
