import {IAquaEditor} from "./aqua-editor";
import {AquaEditorState, useAquaEditorContext} from "./aqua-editor-context";

interface AquaEditorHookResult {
    aquaEditorState: AquaEditorState;
    aquaEditor?: IAquaEditor;
}

/**
 * 指定されたメディアIDに対応するAquaEditorの状態およびインスタンスを取得するフック。
 *
 * @param {string} mediaId - メディアID
 * @returns {AquaEditorHookResult} AquaEditorの状態とインスタンスを含むオブジェクト
 * - `aquaEditorState`: エディタの状態 ("uninitialized" | "loading" | "loaded" | "error")
 * - `aquaEditor`: AquaEditorのインスタンス（エディタが読み込まれている場合のみ提供されます）
 */
export function useAquaEditor(mediaId: string, mediaUrl: string): AquaEditorHookResult {
    const {aquaEditorData} = useAquaEditorContext(mediaId, mediaUrl);
    const editorData = aquaEditorData[mediaId];

    // editorDataがundefinedの場合は "uninitialized" 状態を返す
    if (!editorData) {
        return {
            aquaEditorState: "uninitialized", // デフォルトの状態を設定
            aquaEditor: undefined,
        };
    }

    // 状態が "loaded" である場合のみ aquaEditor にアクセス
    const aquaEditor = editorData.state === "loaded" ? editorData.aquaEditor : undefined;

    return {
        aquaEditorState: editorData.state,
        aquaEditor,
    };
}
