import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("UserTenantItemOutput")
export class UserTenantItemOutput {
    @JsonProperty()
    public name: string = JsonPropertyEmpty;

    @JsonProperty()
    public email: string = JsonPropertyEmpty;
}
