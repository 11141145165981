enum GlobalConfigKey {
    AquaServerUrl = "AQUA_SERVER_URL",
    AquaSiteUrl = "AQUA_SITE_URL",
    ImagefrontUrl = "IMAGEFRONT_URL",
}

declare let __ADMIN_ENV__: {[key: string]: string};

function getConfig(key: GlobalConfigKey): string {
    if (!__ADMIN_ENV__) {
        throw new Error("Env is not configured.");
    }

    if (__ADMIN_ENV__[key] === undefined) {
        throw new Error(`Config key ${key} is not set.`);
    }

    return __ADMIN_ENV__[key];
}

export function getServerUrl() {
    return getConfig(GlobalConfigKey.AquaServerUrl);
}

export function getImagefrontUrl() {
    return getConfig(GlobalConfigKey.ImagefrontUrl);
}
