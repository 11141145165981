import {JsonObject, JsonProperty} from "json2typescript";
import moment from "moment-timezone";

import {DateTimeConverter, JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentContainsPr} from "./document-contains-pr";
import {DocumentPropertyRelatedResourceInputOutput} from "./document-property-related-resource-input-output";
import {DocumentProtectionStatus} from "./document-protection-status";
import {DocumentPublishScope} from "./document-publish-scope";

@JsonObject("DocumentPropertyInputOutput")
export class DocumentPropertyInputOutput {
    @JsonProperty()
    public title: string = JsonPropertyEmpty;

    @JsonProperty()
    public htmlTitle: string = JsonPropertyEmpty;

    @JsonProperty()
    public htmlDescription: string = JsonPropertyEmpty;

    @JsonProperty()
    public htmlKeywords: string = JsonPropertyEmpty;

    @JsonProperty()
    public htmlRobots: string = JsonPropertyEmpty;

    @JsonProperty()
    public htmlScript: string = JsonPropertyEmpty;

    @JsonProperty()
    public note: string = JsonPropertyEmpty;

    @JsonProperty()
    public protectionStatus: DocumentProtectionStatus = JsonPropertyEmpty;

    @JsonProperty()
    public publishScope: DocumentPublishScope = JsonPropertyEmpty;

    @JsonProperty("publishedAt", DateTimeConverter)
    public publishedAt: moment.Moment = JsonPropertyEmpty;

    @JsonProperty("modifiedAt", DateTimeConverter)
    public modifiedAt: moment.Moment = JsonPropertyEmpty;

    @JsonProperty()
    public imageUri: string = JsonPropertyEmpty;

    @JsonProperty()
    public imageAuthorityUri: string = JsonPropertyEmpty;

    @JsonProperty()
    public imageAuthorityTitle: string = JsonPropertyEmpty;

    @JsonProperty()
    public personName: string = JsonPropertyEmpty;

    @JsonProperty()
    public personRole: string = JsonPropertyEmpty;

    @JsonProperty()
    public personImageUri: string = JsonPropertyEmpty;

    @JsonProperty()
    public personSlug: string = JsonPropertyEmpty;

    @JsonProperty()
    public containsPr: DocumentContainsPr = JsonPropertyEmpty;

    @JsonProperty()
    public relatedResources: DocumentPropertyRelatedResourceInputOutput[] = JsonPropertyEmpty;

    public constructor(args?: Partial<DocumentPropertyInputOutput>) {
        Object.assign(this, args);
    }
}
