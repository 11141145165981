import {Button, H3, H5} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

import {useAdminApi} from "../../../api/api-context";
import {Link} from "../../../routes";
import {useMediaListStore} from "../../../stores/media-list-store";
import {createMediaInfo, MediaInfoApi, MediaInfoState} from "../../../utils/media-util";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {MediaCreateSuccessPageStore} from "./media-create-success-page-store";

const CircleDiv = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 5px solid #62d96b;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CheckStyle = styled.div`
    font-size: 60px;
    position: relative;
    width: 1em;
    height: 0.5em;
    border-bottom: 0.15em solid #62d96b;
    border-left: 0.15em solid #62d96b;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
`;

const MediaCreateSuccessPage: React.FC<{pageStore: MediaCreateSuccessPageStore}> = observer(
    ({pageStore}) => {
        const adminApi = useAdminApi();
        const location = useLocation();
        const state = location.state as {id: number; name: string};

        const setMediaInfo = useMediaListStore((state) => state.setInfo);

        const fetchGetMediaApi = async () => {
            try {
                const result = await adminApi.getMediaList();
                if (result) {
                    const infoArray: MediaInfoState[] = [];
                    result.mediaList.forEach((resData: MediaInfoApi) => {
                        infoArray.push(createMediaInfo(resData));
                    });
                    setMediaInfo(infoArray);
                }
            } catch (e) {
                console.error("メディア情報一覧の取得中にエラーが発生しました:", e);
            }
        };

        useEffect(() => {
            fetchGetMediaApi();
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <AppLayout
                breadcrumb={
                    <AdminBreadcrumb
                        factory={(builder) => builder.mediaCreate().mediaCreateSuccess().build()}
                    />
                }
            >
                <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
                    <div style={{width: "400px"}}>
                        <H3 style={{textAlign: "center"}}>メディアが作成されました</H3>
                        <div style={{display: "flex", justifyContent: "center", padding: "28px 0"}}>
                            <CircleDiv>
                                <CheckStyle />
                            </CircleDiv>
                        </div>
                        <div style={{marginBottom: "28px"}}>
                            <H5>メディアID：{state ? state.id : ""}</H5>
                            <H5>メディア名：{state ? state.name : ""}</H5>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Link route={state && `/admin/${state.id}/documents`}>
                        <Button large={true} type="submit" text={"メディアへ移動する"} />
                    </Link>
                </div>
            </AppLayout>
        );
    },
);

export default MediaCreateSuccessPage;
