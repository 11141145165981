import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("ProfileChangeInput")
export class ProfileChangeInput {
    @JsonProperty("name", String)
    public name: string = "";

    @JsonProperty("email", String)
    public email: string = "";

    constructor(init?: Partial<ProfileChangeInput>) {
        Object.assign(this, init);
    }
}
