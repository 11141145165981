import {computed, makeObservable, observable} from "mobx";
import moment from "moment";

import {BigIntId} from "../../../utils/big-int-id";
import {ICategoryItem} from "../../../utils/category-util";
import {DocumentListRevisionItem} from "./document-list-revision-item";

const REVISION_ITEM_COUNT = 5;

export class DocumentListItem {
    @observable
    public id: BigIntId = new BigIntId("0");

    @observable
    public title: string = "";

    @observable
    public documentRevisions: DocumentListRevisionItem[] = [];

    @observable
    public slug: string = "";

    @observable
    public publishedAt: moment.Moment = moment();

    @observable
    public modifiedAt: moment.Moment = moment();

    @observable
    public archived = false;

    @observable
    public categories: ICategoryItem[] = [];

    public constructor(args?: Partial<DocumentListItem>) {
        makeObservable(this);
        Object.assign(this, args);
    }

    @computed
    public get collapsedDocumentRevisions() {
        return this.documentRevisions.slice(0, REVISION_ITEM_COUNT);
    }
}
