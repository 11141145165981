import {DocumentType, DocumentTypeEnum} from "../api/values/document-type";
import {INextBreadcrumbProps} from "../components/molecules/next-breadcrumbs/next-breadcrumbs";
import {AuthenticationStore} from "../stores/authentication-store";
import {BigIntId} from "./big-int-id";
import {IRouteParams} from "./route-util";

export class BreadcrumbItemBuilder {
    public list: INextBreadcrumbProps[] = [];

    private readonly authenticationStore: AuthenticationStore;

    public constructor(authenticationStore: AuthenticationStore) {
        this.authenticationStore = authenticationStore;
    }

    private getMediaId(): string {
        const mediaInfoList = sessionStorage.getItem("mediaInfoList");
        if (mediaInfoList) {
            try {
                const parsedList = JSON.parse(mediaInfoList);
                if (parsedList && parsedList.state && parsedList.state.activeMediaInfo) {
                    return parsedList.state.activeMediaInfo.mediaId || "none";
                }
            } catch (e) {
                console.error("Failed to parse mediaInfoList from sessionStorage", e);
            }
        }
        return "none";
    }

    public custom(label: string, route?: string, params?: IRouteParams) {
        this.list.push({label, route, params});
        return this;
    }

    public documentList() {
        if (this.authenticationStore && this.authenticationStore.hasReadDocumentsPermission) {
            this.custom("ドキュメント一覧", `/admin/${this.getMediaId()}/documents`);
        }
        return this;
    }

    public documentEdit(documentId: BigIntId) {
        if (this.authenticationStore && this.authenticationStore.hasReadDocumentsPermission) {
            this.custom("ドキュメント編集", `/admin/${this.getMediaId()}/documents/[documentId]`, {
                documentId: documentId.value,
            });
        }
        return this;
    }

    public blockList() {
        if (
            this.authenticationStore &&
            this.authenticationStore.hasEditGeneralResourcesPermission
        ) {
            this.custom("ブロック一覧", `/admin/${this.getMediaId()}/documents`, {
                type: DocumentType.BLOCK,
            });
        }
        return this;
    }

    public blockEdit(documentId: BigIntId) {
        if (
            this.authenticationStore &&
            this.authenticationStore.hasEditGeneralResourcesPermission
        ) {
            this.custom("ブロック編集", `/admin/${this.getMediaId()}/documents/[documentId]`, {
                documentId: documentId.value,
            });
        }
        return this;
    }

    public personList() {
        if (
            this.authenticationStore &&
            this.authenticationStore.hasEditGeneralResourcesPermission
        ) {
            this.custom("人物一覧", `/admin/${this.getMediaId()}/documents`, {
                type: DocumentType.PERSON,
            });
        }
        return this;
    }

    public personEdit(documentId: BigIntId) {
        if (
            this.authenticationStore &&
            this.authenticationStore.hasEditGeneralResourcesPermission
        ) {
            this.custom("人物編集", `/admin/${this.getMediaId()}/documents/[documentId]`, {
                documentId: documentId.value,
            });
        }
        return this;
    }

    public documentListByType(documentType: DocumentType) {
        switch (documentType) {
            case DocumentType.ARTICLE:
                this.documentList();
                break;
            case DocumentType.BLOCK:
                this.blockList();
                break;
            case DocumentType.PERSON:
                this.personList();
                break;
        }

        return this;
    }

    public documentListByTypeEnum(documentType: any) {
        switch (documentType) {
            case DocumentTypeEnum.ARTICLE:
                this.documentList();
                break;
            case DocumentTypeEnum.BLOCK:
                this.blockList();
                break;
            case DocumentTypeEnum.PERSON:
                this.personList();
                break;
        }

        return this;
    }

    public documentEditByType(documentType: DocumentType, documentId: BigIntId) {
        switch (documentType) {
            case DocumentType.ARTICLE:
                this.documentEdit(documentId);
                break;
            case DocumentType.BLOCK:
                this.blockEdit(documentId);
                break;
            case DocumentType.PERSON:
                this.personEdit(documentId);
                break;
        }

        return this;
    }

    public documentEditByTypeEnum(documentType: any, documentId: BigIntId) {
        switch (documentType) {
            case DocumentTypeEnum.ARTICLE:
                this.documentEdit(documentId);
                break;
            case DocumentTypeEnum.BLOCK:
                this.blockEdit(documentId);
                break;
            case DocumentTypeEnum.PERSON:
                this.personEdit(documentId);
                break;
        }

        return this;
    }

    public documentRevisionList() {
        if (
            this.authenticationStore &&
            (this.authenticationStore.hasReadDocumentsPermission ||
                this.authenticationStore.hasReadAssignedDocumentsPermission)
        ) {
            this.custom("下書き一覧", `/admin/${this.getMediaId()}/revisions`);
        }
        return this;
    }

    public documentRevisionEdit(documentRevisionId: BigIntId) {
        if (
            this.authenticationStore &&
            (this.authenticationStore.hasReadDocumentsPermission ||
                this.authenticationStore.hasReadAssignedDocumentsPermission)
        ) {
            this.custom(
                "下書き編集",
                `/admin/${this.getMediaId()}/revisions/[documentRevisionId]`,
                {
                    documentRevisionId: documentRevisionId.value,
                },
            );
        }
        return this;
    }

    public documentGroupList() {
        if (
            this.authenticationStore &&
            this.authenticationStore.hasEditGeneralResourcesPermission
        ) {
            this.custom("ドキュメントグループ一覧", `/admin/${this.getMediaId()}/document-groups`);
        }
        return this;
    }

    public userList() {
        if (
            this.authenticationStore &&
            (this.authenticationStore.hasManageAdminUsersPermission ||
                this.authenticationStore.hasManageUsersPermission)
        ) {
            this.custom("ユーザー一覧", `/admin/${this.getMediaId()}/users`);
        }
        return this;
    }

    public mediaList() {
        if (
            this.authenticationStore &&
            (this.authenticationStore.hasReadDocumentsPermission ||
                this.authenticationStore.hasReadAssignedDocumentsPermission)
        ) {
            this.custom("メディア一覧", `/admin/${this.getMediaId()}/media-list`);
        }
        return this;
    }

    public mediaPublishing() {
        if (
            this.authenticationStore &&
            (this.authenticationStore.hasManageAdminUsersPermission ||
                this.authenticationStore.hasManageUsersPermission)
        ) {
            this.custom("メディア公開設定", `/admin/${this.getMediaId()}/media-publishing`);
        }
        return this;
    }

    public mediaDelete() {
        if (this.authenticationStore && this.authenticationStore.hasManageAdminUsersPermission) {
            this.custom("メディアを削除", `/admin/${this.getMediaId()}/media-delete`);
        }
        return this;
    }

    public profile() {
        if (
            this.authenticationStore &&
            (this.authenticationStore.hasReadDocumentsPermission ||
                this.authenticationStore.hasReadAssignedDocumentsPermission)
        ) {
            this.custom("プロフィール", `/admin/${this.getMediaId()}/profile`);
        }
        return this;
    }

    public passwordChange() {
        if (
            this.authenticationStore &&
            (this.authenticationStore.hasReadDocumentsPermission ||
                this.authenticationStore.hasReadAssignedDocumentsPermission)
        ) {
            this.custom("パスワード変更", `/admin/${this.getMediaId()}/password-change`);
        }
        return this;
    }

    public mediaCreate() {
        if (this.authenticationStore && this.authenticationStore.hasManageAdminUsersPermission) {
            this.custom("メディア作成", `/admin/media-create`);
        }
        return this;
    }

    public mediaCreateSuccess() {
        if (this.authenticationStore && this.authenticationStore.hasManageAdminUsersPermission) {
            this.custom("メディア作成完了", `/admin/${this.getMediaId()}/media-create-success`);
        }
        return this;
    }

    public mediaSettings() {
        if (
            this.authenticationStore &&
            (this.authenticationStore.hasManageAdminUsersPermission ||
                this.authenticationStore.hasManageUsersPermission ||
                this.authenticationStore.hasEditGeneralResourcesPermission)
        ) {
            this.custom("メディア基本設定", `/admin/${this.getMediaId()}/:mediaId/media-settings`);
        }
        return this;
    }

    public build() {
        return this.list;
    }
}

export function breadcrumbItems(authenticationStore: AuthenticationStore) {
    return new BreadcrumbItemBuilder(authenticationStore);
}
