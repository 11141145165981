import axios, {AxiosRequestConfig} from "axios";
import {JsonConvert} from "json2typescript";

import {HttpError} from "../api/http-error";
import {NetworkFailureError} from "../api/network-failure-error";
import {getHttpRequestHeaders} from "./http-request-headers";
import {URLSearchParams} from "./url";

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export interface IRequestOptions {
    baseUrl?: string;
    accessToken?: string;
}

const jsonConvert = new JsonConvert();

/**
 * 型指定のないリクエストを実行する非同期関数。
 *
 * @param {HttpMethod} method - HTTPメソッド。
 * @param {string} replacedPath - エンドポイントパス。
 * @param {object} [params] - リクエストパラメータ。
 * @param {IRequestOptions} [options] - リクエストオプション。
 * @param {object} [queryVariables] - クエリ変数。
 * @returns {Promise<any>} - レスポンスデータのPromise。
 * @throws {NetworkFailureError} - ネットワークエラーが発生した場合にスローされます。
 */
async function requestUntyped(
    method: HttpMethod,
    replacedPath: string,
    params?: object,
    options?: IRequestOptions,
    queryVariables?: object,
): Promise<any> {
    const headers = getHttpRequestHeaders({accessToken: options?.accessToken});

    const baseUrl = options?.baseUrl || "";
    const url = baseUrl + replacedPath;

    const config: AxiosRequestConfig = {
        headers,
        method,
        url,
    };

    if (params) {
        if (method === "GET") {
            const sp = new URLSearchParams();
            for (const key of Object.keys(params || {})) {
                const value = (params as any)[key];
                if (value !== undefined) {
                    sp.set(key, value);
                }
            }
            config.params = sp;
        } else {
            if (queryVariables) {
                const sp = new URLSearchParams();
                const keys = Object.keys(queryVariables);
                for (const key of keys) {
                    const value = (queryVariables as any)[key];
                    if (value !== undefined) {
                        sp.set(key, value);
                    }
                }
                config.params = sp;
            }
            config.data = params;
        }
    } else {
        if (queryVariables) {
            const sp = new URLSearchParams();
            const keys = Object.keys(queryVariables);
            for (const key of keys) {
                const value = (queryVariables as any)[key];
                if (value !== undefined) {
                    sp.set(key, value);
                }
            }
            config.params = sp;
        }
    }

    try {
        const response = await axios(config);
        return response.data || {};
    } catch (error: any) {
        const errorMessage = error.response.data.message;
        if (errorMessage) {
            throw new HttpError(errorMessage);
        } else {
            throw new NetworkFailureError();
        }
    }
}

/**
 * パス変数を置換する関数。
 *
 * @param {string} path - エンドポイントパス。
 * @param {object} pathVariables - パス変数。
 * @returns {string} - 置換されたパス。
 * @throws {Error} - パス変数が設定されていない場合にスローされます。
 */
function replacePathVariables(path: string, pathVariables: object): string {
    return path
        .split("/")
        .map((s) => {
            if (!s.startsWith(":")) {
                return s;
            }

            const name = s.substring(1);
            if (!(name in pathVariables)) {
                throw new Error(`Variable for ${s} is not set`);
            }
            return (pathVariables as any)[name];
        })
        .join("/");
}

/**
 * 型指定のあるリクエストを実行する非同期関数。
 *
 * @template TOutput - 出力データの型。
 * @param {HttpMethod} method - HTTPメソッド。
 * @param {string} path - エンドポイントパス。
 * @param {new () => TOutput} outputClass - レスポンスデータのデシリアライズクラス。
 * @param {object} [pathVariables] - パス変数。
 * @param {object} [inputObject] - リクエストの入力データ。
 * @param {IRequestOptions} [options] - リクエストオプション。
 * @param {object} [queryVariables] - クエリ変数。
 * @returns {Promise<TOutput>} - レスポンスデータのPromise。
 */
export async function requestObject<TOutput extends object>(
    method: HttpMethod,
    path: string,
    outputClass: new () => TOutput,
    pathVariables?: object,
    inputObject?: object,
    options?: IRequestOptions,
    queryVariables?: object,
): Promise<TOutput> {
    const request =
        inputObject !== undefined || inputObject != null
            ? jsonConvert.serializeObject(inputObject)
            : null;

    const replacedPath = replacePathVariables(path, pathVariables || {});

    const outputObject = await requestUntyped(
        method,
        replacedPath,
        request,
        options,
        queryVariables,
    );

    const deserializedObject = jsonConvert.deserializeObject(outputObject, outputClass);
    return deserializedObject as TOutput;
}
