import {Button, Dialog} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import React, {useCallback, useEffect, useMemo, useState} from "react";

import {useAdminApi} from "../../../api/api-context";
import {MediaProtectionStatus} from "../../../api/values/media-protection-status";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {DialogBox, DialogTextBox} from "../../molecules/media-confirm-dialog/DialogComponents";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {MediaPublishingPageStore} from "./media-publishing-page-store";

const MediaTitle = styled.b`
    font-size: 1.2rem;
`;

/**
 * メディアの公開・非公開状態を管理するページコンポーネント。
 * メディアの保護状態を取得し、ユーザーがその状態を変更できるようにします。
 */
export const MediaPublishingPage: React.FC<{pageStore: MediaPublishingPageStore}> = observer(
    ({pageStore}) => {
        const adminApi = useAdminApi();
        const activeMediaInfo = useActiveMediaStore((state) => state.activeMediaInfo);
        const activeMediaId = useMemo(() => activeMediaInfo.mediaId, [activeMediaInfo]);
        const activeMediaName = useMemo(() => activeMediaInfo.mediaName, [activeMediaInfo]);
        const [mediaState, setMediaState] = useState<boolean | undefined>(undefined);
        const [isOpen, setIsOpen] = useState<boolean>(false);

        /**
         * メディアの保護状態を判定する関数。
         * @param {string} val - メディアの保護状態（VISIBLEまたはHIDDEN）
         * @returns {boolean | undefined} 公開状態ならtrue、非公開状態ならfalse、その他はundefinedを返す。
         */
        const checkStatus = useCallback((val: string): boolean | undefined => {
            if (val === MediaProtectionStatus.VISIBLE) return true;
            if (val === MediaProtectionStatus.HIDDEN) return false;
            return undefined;
        }, []);

        /**
         * メディア情報をAPIから取得し、stateを更新する。
         * エラーが発生した場合は、コンソールにエラーを表示します。
         */
        const aquaAuth = JSON.parse(localStorage.getItem("aquaAuth") || "{}");
        const isAdmin = aquaAuth.scope && aquaAuth.scope.includes("ADMIN");
        const fetchProtectionStatus = useCallback(async () => {
            try {
                let result;
                if (isAdmin) {
                    result = await adminApi.getMedia({mediaId: activeMediaId});
                } else {
                    result = await adminApi.getMediaMember({mediaId: activeMediaId});
                }
                setMediaState(checkStatus(result.protection_status));
            } catch (error) {
                console.error("メディア情報の取得中にエラーが発生しました:", error);
            }
        }, [isAdmin, checkStatus, adminApi, activeMediaId]);

        // 初回レンダリング時にメディアの保護状態を取得
        useEffect(() => {
            fetchProtectionStatus();
        }, [fetchProtectionStatus]);

        /**
         * ダイアログを開く処理。
         */
        const handleOpen = useCallback(() => setIsOpen(true), []);

        /**
         * ダイアログを閉じる処理。
         */
        const handleClose = useCallback(() => setIsOpen(false), []);

        /**
         * メディアの公開状態を変更する処理。
         * 公開状態によって適切なAPI呼び出しを行い、その後保護状態を再取得します。
         * エラーが発生した場合は、コンソールにエラーを表示します。
         */
        const handleChangeMediaState = useCallback(async () => {
            try {
                if (isAdmin) {
                    if (mediaState) {
                        await adminApi.postMediaPrivate({mediaId: activeMediaId});
                    } else {
                        await adminApi.postMediaPublish({mediaId: activeMediaId});
                    }
                } else {
                    if (mediaState) {
                        await adminApi.postMediaPrivateMember({mediaId: activeMediaId});
                    } else {
                        await adminApi.postMediaPublishMember({mediaId: activeMediaId});
                    }
                }

                fetchProtectionStatus();
            } catch (error) {
                console.error("メディア公開状態の変更中にエラーが発生しました:", error);
            }
            handleClose();
        }, [handleClose, isAdmin, fetchProtectionStatus, mediaState, adminApi, activeMediaId]);

        return (
            <AppLayout
                breadcrumb={
                    <AdminBreadcrumb factory={(builder) => builder.mediaPublishing().build()} />
                }
            >
                <p data-testid="media-publishing-page-heading">
                    <MediaTitle>{activeMediaName}</MediaTitle>を{mediaState ? "非公開" : "公開"}
                    します。
                </p>
                <Button
                    type="button"
                    text={mediaState ? "非公開にする" : "公開する"}
                    onClick={handleOpen}
                    large={true}
                />
                <Dialog isOpen={isOpen} onClose={handleClose} title="状態変更の確認">
                    <DialogTextBox>
                        <div>
                            <p>
                                {activeMediaName}を{mediaState ? "非公開" : "公開"}状態にしますか？
                            </p>
                        </div>
                        <div>
                            <DialogBox
                                onConfirm={handleChangeMediaState}
                                onCancel={handleClose}
                                confirmText={mediaState ? "非公開" : "公開"}
                                cancelText="キャンセル"
                            />
                        </div>
                    </DialogTextBox>
                </Dialog>
            </AppLayout>
        );
    },
);
