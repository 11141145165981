"use client";
import {Icon, Popover, Position} from "@blueprintjs/core";
import styled from "@emotion/styled";
import React, {useEffect} from "react";

import {ActiveMediaInfoState, useActiveMediaStore} from "../../../stores/active-media-store";
import {MediaInfoState, useMediaListStore} from "../../../stores/media-list-store";
import {useAuthenticationStore} from "../../../stores/store-hooks";
import {ResponsiveAnchorButton} from "../../molecules/responsive-labeled-button/responsive-anchor-button";
import SideNavIcon from "../../molecules/side-nav-icon/side-nav-icon";

const SideNavBar: React.FC = () => {
    const authenticationStore = useAuthenticationStore();
    const setActiveMediaInfo = useActiveMediaStore((state) => state.setActiveMediaInfo);
    const activeMediaState: ActiveMediaInfoState = useActiveMediaStore(
        (state) => state.activeMediaInfo,
    );
    const mediaInfoState: MediaInfoState[] = useMediaListStore((state) => state.infoList);

    const handleSideNavClick = (mediaId: string) => {
        mediaInfoState.forEach((mediaInfo) => {
            if (mediaInfo.mediaId === mediaId) {
                setActiveMediaInfo(mediaInfo);
                mediaInfo && authenticationStore.loadPermissions(mediaInfo.role);
            }
        });
    };

    useEffect(() => {
        if (activeMediaState.mediaId === "") {
            mediaInfoState[0] && handleSideNavClick(mediaInfoState[0].mediaId);
        }
    }, [mediaInfoState]); // eslint-disable-line react-hooks/exhaustive-deps

    const Ul = styled.ul`
        list-style: none;
        padding: 0;
        margin: 0;
        overflow-y: scroll;
        height: auto;
        max-height: calc(100vh - 240px);
        scrollbar-width: thin; /* Firefox */
        border-bottom: solid 2px #ffffff;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
        &::-webkit-scrollbar {
            display: none;
        }
    `;

    return (
        // サイドナビゲーションバー
        <div
            style={{
                display: "grid",
                padding: "5px",
                height: "100%",
                backgroundColor: "#D3D8DE",
                boxShadow: "0 0 1px 0",
            }}
        >
            {/* メディア関連アイコン表示部 */}
            <div style={{display: "flex", flexDirection: "column"}}>
                {/* メディア詳細アイコン一覧表示部 */}
                <Ul data-testid="side-nav-media-link-list">
                    {/* メディア詳細アイコン */}
                    {mediaInfoState &&
                        mediaInfoState.map((mediaInfo) => {
                            return (
                                <li key={mediaInfo.mediaId} data-testid="side-nav-media-link-item">
                                    <SideNavIcon
                                        onClick={() => handleSideNavClick(mediaInfo.mediaId)}
                                        isActive={
                                            !!(mediaInfo.mediaId === activeMediaState.mediaId)
                                        }
                                        link={
                                            mediaInfo.role === "PARTNER"
                                                ? `/admin/${mediaInfo.mediaId}/revisions`
                                                : `/admin/${mediaInfo.mediaId}/documents`
                                        }
                                    >
                                        <img
                                            src={mediaInfo.mediaFavicon}
                                            alt={mediaInfo.mediaTitle}
                                            style={{
                                                objectFit: "cover",
                                                height: "50px",
                                            }}
                                        />
                                    </SideNavIcon>
                                </li>
                            );
                        })}
                </Ul>
                {/* メディア一覧アイコン */}
                <div data-testid="side-nav-media-list">
                    <SideNavIcon link="/admin/media-list">
                        <Icon icon="more" />
                    </SideNavIcon>
                </div>
                {/* ユーザー情報更新 */}
                <div style={{marginTop: "auto"}} data-testid="side-nav-person">
                    <Popover
                        content={
                            <div style={{padding: "10px"}}>
                                <ResponsiveAnchorButton
                                    text="プロフィール"
                                    minimal={true}
                                    href="/admin/profile"
                                />

                                <ResponsiveAnchorButton
                                    text="パスワード変更"
                                    minimal={true}
                                    href="/admin/password-change"
                                />
                            </div>
                        }
                        position={Position.RIGHT}
                        interactionKind="hover"
                        hoverOpenDelay={0}
                    >
                        <SideNavIcon>
                            <Icon icon="person" />
                        </SideNavIcon>
                    </Popover>
                </div>

                {/* ログアウト */}
                <div data-testid="side-nav-logout">
                    <SideNavIcon link="/admin/logout">
                        <Icon icon="log-out" />
                    </SideNavIcon>
                </div>
            </div>
        </div>
    );
};

export default SideNavBar;
