import {action, computed, makeObservable, observable} from "mobx";

import {AuthError} from "../api/auth-error";
import {ResourceForbiddenError} from "../errors/resource-forbidden-error";
import {
    getAuthInfo,
    IAuthInfo,
    removeAuthInfo,
    revokeRefreshToken,
    setAuthInfo,
    tryAuthenticate,
    tryRefreshToken,
} from "../utils/auth-util";

// See https://github.com/syginc/aqua-docs/wiki/%E3%83%AD%E3%83%BC%E3%83%AB%E3%81%A8%E6%A8%A9%E9%99%90
export enum Permission {
    MANAGE_SYSTEM = "MANAGE_SYSTEM", //AomJSONモード等、メディア運営外の操作
    MANAGE_ADMIN_USERS = "MANAGE_ADMIN_USERS", //システム管理者ユーザーの管理
    MANAGE_USERS = "MANAGE_USERS", //システム管理者を除くユーザーの管理
    EDIT_GENERAL_RESOURCES = "EDIT_GENERAL_RESOURCES", // ドキュメントの公開・非公開やslugの変更等一般的なリソースに対する操作
    MANAGE_DOCUMENTS = "MANAGE_DOCUMENTS", // 下書きの作成
    READ_DOCUMENTS = "READ_DOCUMENTS", //記事一覧の表示、閲覧、下書きの編集
    WRITE_DOCUMENTS = "WRITE_DOCUMENTS", //未使用
    WRITE_ASSIGNED_DOCUMENTS = "WRITE_ASSIGNED_DOCUMENTS", //未使用
    READ_ASSIGNED_DOCUMENTS = "READ_ASSIGNED_DOCUMENTS", //自分が担当となっている記事の表示、読み書き
    //以下tellus-admin
    MANAGE_MEDIA = "MANAGE_MEDIA", //メディアの作成・編集・削除（A2で新設予定）
    EDIT_MEDIA = "EDIT_MEDIA", //メディア情報の編集（A2で新設予定）
    MANAGE_TENANT = "MANAGE_TENANT", //テナントの作成・編集・削除（A2で新設予定）
    MANAGE_MEDIA_USERS = "MANAGE_MEDIA_USERS", //テナント配下のユーザーの管理（A2で新設予定）
    READ_MEDIA = "READ_MEDIA", //メディア情報の閲覧（A2で新設予定）
    READ_TENANT = "READ_TENANT", //テナント情報の閲覧（A2で新設予定）
}

const scopePermissionMap: {[key: string]: Permission[]} = {
    ADMIN: [
        Permission.MANAGE_SYSTEM,
        Permission.MANAGE_ADMIN_USERS,
        Permission.MANAGE_USERS,
        Permission.EDIT_GENERAL_RESOURCES,
        Permission.MANAGE_DOCUMENTS,
        Permission.READ_DOCUMENTS,
        Permission.WRITE_DOCUMENTS,

        // tellus以降
        Permission.MANAGE_MEDIA_USERS,
        Permission.MANAGE_TENANT,
        Permission.MANAGE_MEDIA,
        Permission.EDIT_MEDIA,
        Permission.READ_MEDIA,
    ],

    MANAGER: [
        Permission.MANAGE_USERS,
        Permission.EDIT_GENERAL_RESOURCES,
        Permission.MANAGE_DOCUMENTS,
        Permission.READ_DOCUMENTS,
        Permission.WRITE_DOCUMENTS, //未使用？
        // tellus以降
        Permission.MANAGE_MEDIA_USERS,
        Permission.EDIT_MEDIA,
        Permission.READ_MEDIA,
    ],

    EDITOR: [
        Permission.EDIT_GENERAL_RESOURCES,
        Permission.MANAGE_DOCUMENTS,
        Permission.READ_DOCUMENTS,
        Permission.WRITE_DOCUMENTS, //未使用？
        // tellus以降
        Permission.READ_MEDIA,
    ],

    WRITER: [Permission.READ_DOCUMENTS, Permission.WRITE_DOCUMENTS],

    PARTNER: [
        Permission.READ_ASSIGNED_DOCUMENTS,
        Permission.WRITE_ASSIGNED_DOCUMENTS, //未使用？
    ],

    ALL: [Permission.READ_ASSIGNED_DOCUMENTS],
};

// eslint-disable-next-line max-classes-per-file
export class AuthenticationStore {
    @observable
    public isAuthenticatedCache?: boolean = undefined;

    @observable
    public permissionCache?: Set<Permission> = undefined;

    @observable
    public usernameCache?: string | null = undefined;

    @observable
    public mediaRole?: string | null = null;

    constructor() {
        makeObservable(this);
    }

    @computed
    public get isAuthenticated() {
        this.checkAuthenticated();
        return this.isAuthenticatedCache;
    }

    @computed
    public get username() {
        this.checkAuthenticated();
        return this.usernameCache;
    }

    @computed
    public get hasManageSystemPermission() {
        return this.hasPermission(Permission.MANAGE_SYSTEM);
    }

    @computed
    public get hasManageAdminUsersPermission() {
        return this.hasPermission(Permission.MANAGE_ADMIN_USERS);
    }

    @computed
    public get hasManageUsersPermission() {
        return this.hasPermission(Permission.MANAGE_USERS);
    }

    @computed
    public get hasEditGeneralResourcesPermission() {
        return this.hasPermission(Permission.EDIT_GENERAL_RESOURCES);
    }

    @computed
    public get hasManageDocumentsPermission() {
        return this.hasPermission(Permission.MANAGE_DOCUMENTS);
    }

    @computed
    public get hasReadDocumentsPermission() {
        return this.hasPermission(Permission.READ_DOCUMENTS);
    }

    @computed
    public get hasWriteDocumentsPermission() {
        return this.hasPermission(Permission.WRITE_DOCUMENTS);
    }

    @computed
    public get hasReadAssignedDocumentsPermission() {
        return this.hasPermission(Permission.READ_ASSIGNED_DOCUMENTS);
    }

    @computed
    public get hasWriteAssignedDocumentsPermission() {
        return this.hasPermission(Permission.WRITE_ASSIGNED_DOCUMENTS);
    }
    @computed
    public get hasEditMediaPermission() {
        return this.hasPermission(Permission.EDIT_MEDIA);
    }
    @computed
    public get hasReadMediaPermission() {
        return this.hasPermission(Permission.READ_MEDIA);
    }
    @computed
    public get hasManageMediaUserPermission() {
        return this.hasPermission(Permission.MANAGE_MEDIA_USERS);
    }
    @computed
    public get hasManageMediaPermission() {
        return this.hasPermission(Permission.MANAGE_MEDIA);
    }

    public isUsernameIn(usernames: string[]) {
        this.checkAuthenticated();
        return this.usernameCache && usernames.includes(this.usernameCache);
    }

    public hasAllUsersOrLimitedUserPermissionExist(
        allUserPermission: Permission,
        limitedUserPermission: Permission,
        limitedUsernames: string[],
    ) {
        if (this.hasPermission(allUserPermission)) {
            return true;
        }

        if (this.hasPermission(limitedUserPermission) && this.isUsernameIn(limitedUsernames)) {
            return true;
        }

        return false;
    }

    public checkAnyPermissionExists(...permissions: Permission[]) {
        for (const permission of permissions) {
            if (this.hasPermission(permission)) {
                return true;
            }
        }
        throw new ResourceForbiddenError();
    }

    public checkAllUsersOrLimitedUserPermissionExist(
        allUserPermission: Permission,
        limitedUserPermission: Permission,
        limitedUsernames: string[],
    ) {
        if (
            !this.hasAllUsersOrLimitedUserPermissionExist(
                allUserPermission,
                limitedUserPermission,
                limitedUsernames,
            )
        ) {
            throw new ResourceForbiddenError();
        }
    }

    @action.bound
    public async login(username: string, password: string) {
        const token = await tryAuthenticate(username, password);
        this.setToken(token);
    }

    @action.bound
    public async getOrRefreshToken(): Promise<string | undefined> {
        const authInfo = getAuthInfo();
        if (!authInfo) {
            return undefined;
        }

        const now = new Date().getTime();
        if (now >= authInfo.expiredAt) {
            try {
                const token = await tryRefreshToken(authInfo);
                this.setToken(token);
                return token.accessToken;
            } catch (error) {
                if (error instanceof AuthError) {
                    console.log("AuthError on refresh");
                    this.setToken(null);
                }
                throw error;
            }
        }
        return authInfo.accessToken;
    }

    @action.bound
    public checkAuthenticated() {
        if (this.isAuthenticatedCache === undefined) {
            this.updateCache(getAuthInfo());
        }
    }

    @action.bound
    public async discardToken() {
        try {
            await revokeRefreshToken();
        } catch (e) {
            // eslint-disable-next-line no-empty
        }
        removeAuthInfo();
        this.updateCache(null);
    }

    @action.bound
    private setToken(token: IAuthInfo | null) {
        setAuthInfo(token);
        this.updateCache(token);
    }

    @action.bound
    public setMediaRole(role: string) {
        this.mediaRole = role;
        if (typeof window !== "undefined") {
            localStorage.setItem("mediaRole", role);
        }
    }

    @action.bound
    public loadPermissions(role: string) {
        const permissions = scopePermissionMap[role];
        if (permissions) {
            this.permissionCache = new Set(permissions);
        }
    }

    @action.bound
    private updateCache(authInfo: IAuthInfo | null) {
        if (authInfo && authInfo.scope) {
            const permissions = authInfo.scope
                .split(/\s+/)
                .flatMap((scope) => scopePermissionMap[scope] || []);
            this.permissionCache = new Set(permissions);
        }
        this.isAuthenticatedCache = !!authInfo;
        this.usernameCache = authInfo ? authInfo.username : null;
    }

    private hasPermission(permission: Permission) {
        this.checkAuthenticated();
        return this.permissionCache!.has(permission);
    }
}

export const authenticationStore = new AuthenticationStore();
