import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {MediaProtectionStatus} from "./media-protection-status";

@JsonObject("MediaItem")
export class MediaItem {
    @JsonProperty("id", String)
    public id: string = JsonPropertyEmpty;

    @JsonProperty("name", String)
    public name: string = JsonPropertyEmpty;

    @JsonProperty("summary", String)
    public summary: string = JsonPropertyEmpty;

    @JsonProperty("base_url", String)
    public base_url: string = JsonPropertyEmpty;

    @JsonProperty("title", String)
    public title: string = JsonPropertyEmpty;

    @JsonProperty("description", String)
    public description: string = JsonPropertyEmpty;

    @JsonProperty("og_image_url", String)
    public og_image_url: string = JsonPropertyEmpty;

    @JsonProperty("favicon_url", String)
    public favicon_url: string = JsonPropertyEmpty;

    @JsonProperty("thumbnail_url", String)
    public thumbnail_url: string = JsonPropertyEmpty;

    @JsonProperty("protection_status", String)
    public protection_status: MediaProtectionStatus = JsonPropertyEmpty;

    @JsonProperty("api_token", String)
    public api_token: string = JsonPropertyEmpty;

    @JsonProperty("role", String)
    public role: string = JsonPropertyEmpty;
}

@JsonObject("MediaListOutput")
export class MediaListOutput {
    @JsonProperty("mediaList", [MediaItem])
    public mediaList: MediaItem[] = [];
}
