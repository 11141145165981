import {Intent, Position, Toaster} from "@blueprintjs/core";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";

import {useAdminApi} from "../../../api/api-context";
import {MediaProtectionStatus} from "../../../api/values/media-protection-status";
import {MediaUpdateInput} from "../../../api/values/media-update-input";
import {useActiveMediaStore} from "../../../stores/active-media-store";
import {MediaInfoState, useMediaListStore} from "../../../stores/media-list-store";
import {createMediaInfo} from "../../../utils/media-util";
import {AdminBreadcrumb} from "../../organisms/admin-breadcrumb/admin-breadcrumb";
import MediaForm from "../../organisms/media-form/media-form";
import {AppLayout} from "../../templates/app-layout/app-layout";
import {MediaSettingsPageStore} from "./media-setting-page-store";

const MediaSettingsPage: React.FC<{pageStore: MediaSettingsPageStore}> = observer(({pageStore}) => {
    const adminApi = useAdminApi();
    const [mediaName, setMediaName] = useState("");
    const [mediaSummary, setMediaSummary] = useState("");
    const [mediaTitle, setMediaTitle] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    const [mediaDescription, setMediaDescription] = useState("");
    const [faviconFileUri, setFaviconFileUri] = useState<string>("");
    const [thumbnailFileUri, setThumbnailFileUri] = useState<string>("");
    const [ogImageFileUri, setOgImageFileUri] = useState<string>("");
    const activeMediaState = useActiveMediaStore((state) => state.activeMediaInfo);
    const setMediaList = useMediaListStore((state) => state.setInfo);

    const fetchGetMediaDetailApi = async () => {
        try {
            const aquaAuthString = localStorage.getItem("aquaAuth");
            if (!aquaAuthString) {
                throw new Error("認証情報が見つかりません");
            }
            const aquaAuth = JSON.parse(aquaAuthString);
            const isAdmin = aquaAuth.scope?.includes("ADMIN");
            let result;
            if (isAdmin) {
                result = await adminApi.getMedia({mediaId: activeMediaState.mediaId});
            } else {
                result = await adminApi.getMediaMember({mediaId: activeMediaState.mediaId});
            }
            if (result) {
                setMediaFields(result);
            }
        } catch (e) {
            console.error("メディア情報の取得中にエラーが発生しました:", e);
            Toaster.create({position: Position.TOP}).show({
                message: "メディア情報の取得中にエラーが発生しました",
                intent: Intent.DANGER,
            });
        }
    };

    const fetchPutMediaDetailApi = async () => {
        try {
            const aquaAuthString = localStorage.getItem("aquaAuth");
            if (!aquaAuthString) {
                throw new Error("認証情報が見つかりません");
            }
            const aquaAuth = JSON.parse(localStorage.getItem("aquaAuth") || "{}");
            const isAdmin = aquaAuth.scope && aquaAuth.scope.includes("ADMIN");

            let result;

            const mediaUpdateInput = new MediaUpdateInput({
                name: mediaName,
                summary: mediaSummary,
                title: mediaTitle,
                base_url: mediaUrl,
                description: mediaDescription,
                favicon_url: faviconFileUri,
                thumbnail_url: thumbnailFileUri,
                og_image_url: ogImageFileUri,
                protection_status: MediaProtectionStatus.HIDDEN,
            });

            if (isAdmin) {
                result = await adminApi.putMediaUpdate(
                    {mediaId: activeMediaState.mediaId},
                    mediaUpdateInput,
                );
            } else {
                result = await adminApi.putMediaUpdateMember(
                    {mediaId: activeMediaState.mediaId},
                    mediaUpdateInput,
                );
            }
            if (result) {
                Toaster.create({position: Position.TOP}).show({
                    message: "メディア情報が更新されました",
                    intent: Intent.SUCCESS,
                });
                const mediaListResult = await adminApi.getMediaList();
                const mediaList: MediaInfoState[] = mediaListResult.mediaList.map(createMediaInfo);
                setMediaList(mediaList);
            }
        } catch (e) {
            console.error("メディア情報の更新にエラーが発生しました:", e);
            Toaster.create({position: Position.TOP}).show({
                message: "メディア情報の更新中にエラーが発生しました",
                intent: Intent.DANGER,
            });
        }
    };

    const setMediaFields = (result: any) => {
        setMediaName(result.name);
        setMediaSummary(result.summary);
        setMediaTitle(result.title);
        setMediaUrl(result.base_url);
        setMediaDescription(result.description);
        setFaviconFileUri(result.favicon_url);
        setThumbnailFileUri(result.thumbnail_url);
        setOgImageFileUri(result.og_image_url);
    };

    useEffect(() => {
        if (activeMediaState.mediaId) {
            fetchGetMediaDetailApi();
        }
    }, [activeMediaState.mediaId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AppLayout
            breadcrumb={<AdminBreadcrumb factory={(builder) => builder.mediaSettings().build()} />}
        >
            <MediaForm
                mediaName={mediaName}
                setMediaName={setMediaName}
                mediaSummary={mediaSummary}
                setMediaSummary={setMediaSummary}
                mediaTitle={mediaTitle}
                setMediaTitle={setMediaTitle}
                mediaUrl={mediaUrl}
                setMediaUrl={setMediaUrl}
                mediaDescription={mediaDescription}
                setMediaDescription={setMediaDescription}
                faviconFileUri={faviconFileUri}
                setFaviconFileUri={setFaviconFileUri}
                thumbnailFileUri={thumbnailFileUri}
                setThumbnailFileUri={setThumbnailFileUri}
                ogImageFileUri={ogImageFileUri}
                setOgImageFileUri={setOgImageFileUri}
                onSubmit={fetchPutMediaDetailApi}
                buttonText="設定を変更する"
            />
        </AppLayout>
    );
});

export default MediaSettingsPage;
