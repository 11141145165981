import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";
import {UserTenantItemOutput} from "./user-tenant-item-output";

@JsonObject("UserTenantListItemOutput")
export class UserTenantListItemOutput {
    @JsonProperty("users", [UserTenantItemOutput])
    public users: UserTenantItemOutput[] = JsonPropertyEmpty;
}
