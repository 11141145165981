import {JsonObject, JsonProperty} from "json2typescript";
import moment from "moment-timezone";

import {BigIntId} from "../../utils/big-int-id";
import {BigIntIdConverter, DateTimeConverter, JsonPropertyEmpty} from "../../utils/json-util";
import {DocumentPropertyRelatedResourceInputOutput} from "./document-property-related-resource-input-output";
import {DocumentRevisionType} from "./document-revision-type";
import {DocumentRevisionUserInputOutput} from "./document-revision-user-input-output";

@JsonObject("DocumentRevisionListItemOutput")
export class DocumentRevisionListItemOutput {
    @JsonProperty("id", BigIntIdConverter)
    public id: BigIntId = JsonPropertyEmpty;

    @JsonProperty("documentId", BigIntIdConverter)
    public documentId: BigIntId = JsonPropertyEmpty;

    @JsonProperty()
    public revisionType: DocumentRevisionType = JsonPropertyEmpty;

    @JsonProperty()
    public revisionNumber: number | null = JsonPropertyEmpty;

    @JsonProperty()
    public revisionName: string = JsonPropertyEmpty;

    @JsonProperty("appliedAt", DateTimeConverter)
    public appliedAt: moment.Moment | null = JsonPropertyEmpty;

    @JsonProperty("updatedAt", DateTimeConverter)
    public updatedAt: moment.Moment = JsonPropertyEmpty;

    @JsonProperty("publishedAt", DateTimeConverter)
    public publishedAt: moment.Moment = JsonPropertyEmpty;

    @JsonProperty("modifiedAt", DateTimeConverter)
    public modifiedAt: moment.Moment = JsonPropertyEmpty;

    @JsonProperty()
    public title: string = JsonPropertyEmpty;

    @JsonProperty("relatedResources", [DocumentPropertyRelatedResourceInputOutput])
    public relatedResources: DocumentPropertyRelatedResourceInputOutput[] = JsonPropertyEmpty;

    @JsonProperty("users", [DocumentRevisionUserInputOutput])
    public users: DocumentRevisionUserInputOutput[] = JsonPropertyEmpty;
}
