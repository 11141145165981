import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

export interface MediaInfoState {
    mediaId: string;
    mediaName: string;
    mediaSummary: string;
    mediaUrl: string;
    mediaTitle: string;
    mediaDescription: string;
    mediaOgImage: string;
    mediaFavicon: string;
    mediaThumbnail: string;
    mediaProtectionStatus: "1" | "2";
    mediaApiToken: string;
    role: string;
}

const mediaInfoList: MediaInfoState[] = [];

type useMediaListStore = {
    infoList: MediaInfoState[];
    setInfo: (info: MediaInfoState[]) => void;
};

export const useMediaListStore = create<useMediaListStore>()(
    persist(
        (set) => ({
            infoList: mediaInfoList,
            setInfo: (info: MediaInfoState[]) => set({infoList: info}),
        }),
        {
            name: "mediaInfoList", // ローカルストレージでのキー名
            storage: createJSONStorage<useMediaListStore>(() => localStorage), // JSONのシリアライズとデシリアライズを設定
        },
    ),
);
