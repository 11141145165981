import {JsonObject, JsonProperty} from "json2typescript";

import {JsonPropertyEmpty} from "../../utils/json-util";

@JsonObject("PasswordChangeInput")
export class PasswordChangeInput {
    @JsonProperty("password")
    public password: string = JsonPropertyEmpty;
    @JsonProperty("newPassword")
    public newPassword: string = JsonPropertyEmpty;

    public constructor(args?: Partial<PasswordChangeInput>) {
        Object.assign(this, args);
    }
}
