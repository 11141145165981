import {Button, FormGroup, Intent} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {Imagefront} from "@syginc/imagefront-client";
import React, {useEffect, useState} from "react";

import {Permission} from "../../../stores/authentication-store";
import {useAuthenticationStore} from "../../../stores/store-hooks";
import {createImagefrontOption} from "../../../utils/imagefront-util";
import FormInputGroup from "../../molecules/form-input-group/form-input-group";
import {UploadableImage} from "../../molecules/uploadable-image/uploadable-image";

export interface MediaFormProps {
    mediaName: string;
    setMediaName: React.Dispatch<React.SetStateAction<string>>;
    mediaSummary: string;
    setMediaSummary: React.Dispatch<React.SetStateAction<string>>;
    mediaTitle: string;
    setMediaTitle: React.Dispatch<React.SetStateAction<string>>;
    mediaUrl: string;
    setMediaUrl: React.Dispatch<React.SetStateAction<string>>;
    mediaDescription: string;
    setMediaDescription: React.Dispatch<React.SetStateAction<string>>;
    faviconFileUri: string;
    setFaviconFileUri: React.Dispatch<React.SetStateAction<string>>;
    thumbnailFileUri: string;
    setThumbnailFileUri: React.Dispatch<React.SetStateAction<string>>;
    ogImageFileUri: string;
    setOgImageFileUri: React.Dispatch<React.SetStateAction<string>>;
    onSubmit: () => void;
    buttonText: string;
}

const StyledUploadableImageFavicon = styled(UploadableImage)`
    width: 75px;
    height: 75px;
`;
const StyledUploadableImageThumbnail = styled(UploadableImage)`
    width: 300px;
    height: 150px;
`;
const StyledUploadableImageOg = styled(UploadableImage)`
    width: 240px;
    height: 126px;
`;

const MediaForm: React.FC<MediaFormProps> = ({
    mediaName,
    setMediaName,
    mediaSummary,
    setMediaSummary,
    mediaTitle,
    setMediaTitle,
    mediaUrl,
    setMediaUrl,
    mediaDescription,
    setMediaDescription,
    faviconFileUri,
    setFaviconFileUri,
    thumbnailFileUri,
    setThumbnailFileUri,
    ogImageFileUri,
    setOgImageFileUri,
    onSubmit,
    buttonText,
}) => {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [mediaNameError, setMediaNameError] = useState("");
    const [mediaUrlError, setMediaUrlError] = useState("");

    const imagefrontTokenProvider = async () => {
        return (await authenticationStore.getOrRefreshToken())!;
    };

    const buildImageFilename = (file: File): string => {
        return `${file.name}`;
    };

    const imagefront = new Imagefront(
        createImagefrontOption(buildImageFilename, imagefrontTokenProvider),
    );

    const validateMediaName = () => {
        if (!mediaName) {
            setMediaNameError("メディア名は必須です。");
            return false;
        }
        setMediaNameError("");
        return true;
    };

    const validateMediaUrl = () => {
        if (!mediaUrl) {
            setMediaUrlError("URLは必須です。");
            return false;
        } else if (!/^http.*:\/\/.*/.test(mediaUrl)) {
            setMediaUrlError("URLはhttps://から始まる必要があります。");
            return false;
        }
        setMediaUrlError("");
        return true;
    };

    useEffect(() => {
        const isMediaNameValid = mediaName && !mediaNameError;
        const isMediaUrlValid = mediaUrl && !mediaUrlError;

        setSubmitDisabled(!(isMediaNameValid && isMediaUrlValid));
    }, [mediaName, mediaUrl, mediaNameError, mediaUrlError]);
    const authenticationStore = useAuthenticationStore();
    const permissionCache = authenticationStore.permissionCache;
    // メディアの概念が整理されるまで暫定でADMIN権限処理
    const hasPermission = permissionCache?.has(Permission.MANAGE_SYSTEM);

    return (
        <div style={{width: "100%"}}>
            <div style={{padding: "28px 0"}}>
                <FormInputGroup
                    label={
                        <span>
                            メディア名<span style={{color: "red"}}> (必須)</span>
                        </span>
                    }
                    labelFor="media-name-input"
                    placeholder="メディア名を入力してください"
                    value={mediaName}
                    onChange={(e) => setMediaName(e.target.value)}
                    onBlur={validateMediaName}
                    helperText={mediaNameError}
                    intent={mediaNameError ? Intent.DANGER : Intent.NONE}
                    disabled={!hasPermission}
                />
                <FormInputGroup
                    label="概要"
                    labelFor="media-summary-input"
                    placeholder="メディア概要を入力してください"
                    value={mediaSummary}
                    onChange={(e) => setMediaSummary(e.target.value)}
                    disabled={!hasPermission}
                />
                <FormInputGroup
                    label="title"
                    labelFor="media-title-input"
                    placeholder="タイトルを入力してください"
                    value={mediaTitle}
                    onChange={(e) => setMediaTitle(e.target.value)}
                    disabled={!hasPermission}
                />
                <FormInputGroup
                    label={
                        <span>
                            URL<span style={{color: "red"}}> (必須)</span>
                        </span>
                    }
                    labelFor="media-url-input"
                    placeholder="メディアURLを入力してください"
                    value={mediaUrl}
                    onChange={(e) => setMediaUrl(e.target.value)}
                    onBlur={validateMediaUrl}
                    helperText={mediaUrlError}
                    intent={mediaUrlError ? Intent.DANGER : Intent.NONE}
                    disabled={!hasPermission}
                />
                <FormInputGroup
                    label="description"
                    labelFor="media-description-input"
                    placeholder="メディアの説明を入力してください"
                    value={mediaDescription}
                    onChange={(e) => setMediaDescription(e.target.value)}
                    type="textarea"
                    disabled={!hasPermission}
                />
                {imagefront && (
                    <>
                        <FormGroup
                            label="favicon"
                            labelFor="media-favicon-input"
                            helperText="推奨サイズ: 48x48、SVG拡張子"
                            style={{position: "relative"}}
                        >
                            {faviconFileUri && (
                                <Button
                                    data-testid="remove-favicon-button"
                                    onClick={() => setFaviconFileUri("")}
                                    icon="cross"
                                    style={{
                                        position: "absolute",
                                        top: "24px",
                                        left: "85px",
                                        zIndex: 1,
                                    }}
                                ></Button>
                            )}
                            <StyledUploadableImageFavicon
                                imagefront={imagefront}
                                imageUri={faviconFileUri}
                                onUploaded={(uri) => setFaviconFileUri(uri)}
                                isEditable={true}
                            />
                        </FormGroup>
                        <FormGroup
                            label="thumbnail"
                            labelFor="media-thumbnail-input"
                            helperText="推奨サイズ: 1200x600"
                            style={{position: "relative"}}
                        >
                            {thumbnailFileUri && (
                                <Button
                                    data-testid="remove-thumbnail-button"
                                    onClick={() => setThumbnailFileUri("")}
                                    icon="cross"
                                    style={{
                                        position: "absolute",
                                        top: "27px",
                                        left: "266px",
                                        zIndex: 1,
                                    }}
                                ></Button>
                            )}
                            <StyledUploadableImageThumbnail
                                imagefront={imagefront}
                                imageUri={thumbnailFileUri}
                                onUploaded={(uri) => setThumbnailFileUri(uri)}
                                isEditable={true}
                            />
                        </FormGroup>
                        <FormGroup
                            label="og:image"
                            labelFor="media-og-image-input"
                            helperText="推奨サイズ: 1200x630"
                            style={{position: "relative"}}
                        >
                            {ogImageFileUri && (
                                <Button
                                    data-testid="remove-og-image-button"
                                    onClick={() => setOgImageFileUri("")}
                                    icon="cross"
                                    style={{
                                        position: "absolute",
                                        top: "27px",
                                        left: "206px",
                                        zIndex: 1,
                                    }}
                                ></Button>
                            )}
                            <StyledUploadableImageOg
                                imagefront={imagefront}
                                imageUri={ogImageFileUri}
                                onUploaded={(uri) => setOgImageFileUri(uri)}
                                isEditable={true}
                            />
                        </FormGroup>
                    </>
                )}
            </div>
            <Button
                type="submit"
                text={buttonText}
                disabled={submitDisabled || !hasPermission}
                onClick={onSubmit}
            />
        </div>
    );
};

export default MediaForm;
