import React, {useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";

import {useActiveMediaStore} from "../stores/active-media-store";
import {MediaInfoState, useMediaListStore} from "../stores/media-list-store";
import {DocumentEditPage} from "./pages/document-edit-page/document-edit-page";
import {DocumentEditPageStore} from "./pages/document-edit-page/document-edit-page-store";
import {DocumentGroupEditPage} from "./pages/document-group-edit-page/document-group-edit-page";
import {DocumentGroupEditPageStore} from "./pages/document-group-edit-page/document-group-edit-page-store";
import {DocumentGroupListPage} from "./pages/document-group-list-page/document-group-list-page";
import {DocumentGroupListPageStore} from "./pages/document-group-list-page/document-group-list-page-store";
import {DocumentListPage} from "./pages/document-list-page/document-list-page";
import {DocumentListPageStore} from "./pages/document-list-page/document-list-page-store";
import {GoPage} from "./pages/go-page/go-page";
import {GoPageStore} from "./pages/go-page/go-page-store";
import {HomePage} from "./pages/home-page/home-page";
import {ImportPage} from "./pages/import-page/import-page";
import {ImportPageStore} from "./pages/import-page/import-page-store";
import {LoginPage} from "./pages/login-page/login-page";
import {LoginPageStore} from "./pages/login-page/login-page-store";
import {LogoutPage} from "./pages/logout-page/logout-page";
import MediaCreatePage from "./pages/media-create-page/media-create-page";
import {MediaCreatePageStore} from "./pages/media-create-page/media-create-page-store";
import MediaCreateSuccessPage from "./pages/media-create-success-page/media-create-success-page";
import {MediaCreateSuccessPageStore} from "./pages/media-create-success-page/media-create-success-page-store";
import {MediaDeletePage} from "./pages/media-delete-page/media-delete-page";
import {MediaDeletePageStore} from "./pages/media-delete-page/media-delete-page-store";
import MediaListPage from "./pages/media-list-page/media-list-page";
import {MediaListPageStore} from "./pages/media-list-page/media-list-page-store";
import {MediaPublishingPage} from "./pages/media-publishing-page/media-publishing-page";
import {MediaPublishingPageStore} from "./pages/media-publishing-page/media-publishing-page-store";
import {MediaSettingsPageStore} from "./pages/media-settings-page/media-setting-page-store";
import MediaSettingsPage from "./pages/media-settings-page/media-settings-page";
import {MigrationPage} from "./pages/migration-page/migration-page";
import {MigrationPageStore} from "./pages/migration-page/migration-page-store";
import {PasswordChangePage} from "./pages/password-change-page/password-change-page";
import {PasswordChangePageStore} from "./pages/password-change-page/password-change-page-store";
import {PasswordResetPage} from "./pages/password-reset-page/password-reset-page";
import {PasswordResetPageStore} from "./pages/password-reset-page/password-reset-page-store";
import {PasswordResetRequestPage} from "./pages/password-reset-page/password-reset-request-page";
import {PasswordResetRequestPageStore} from "./pages/password-reset-page/password-reset-request-page-store";
import {ProfilePage} from "./pages/profile-page/profile-page";
import {ProfilePageStore} from "./pages/profile-page/profile-page-store";
import {RevisionDetailPage} from "./pages/revision-detail-page/revision-detail-page";
import {DocumentRevisionDetailPageStore} from "./pages/revision-detail-page/revision-detail-page-store";
import {RevisionListPage} from "./pages/revision-list-page/revision-list-page";
import {RevisionListPageStore} from "./pages/revision-list-page/revision-list-page-store";
import {UserEditPage} from "./pages/user-edit/user-edit-page";
import {UserEditPageStore} from "./pages/user-edit/user-edit-page-store";
import {UserListPage} from "./pages/user-list/user-list-page";
import {UserListPageStore} from "./pages/user-list/user-list-page-store";
import {RenderPage} from "./render-page";

export const AppRouter: React.FC = () => {
    const location = useLocation();
    const mediaId = `${location.pathname.split("/")[2]}` || 0;
    const setActiveMedia: (info: MediaInfoState) => void = useActiveMediaStore(
        (state) => state.setActiveMediaInfo,
    );
    const mediaInfoState: MediaInfoState[] = useMediaListStore((state) => state.infoList);
    useEffect(() => {
        mediaInfoState.forEach((mediaInfo) => {
            if (mediaInfo.mediaId === mediaId) {
                setActiveMedia(mediaInfo);
            }
        });
    }, [mediaId, mediaInfoState, setActiveMedia]);

    return (
        <Routes>
            <Route path="/admin" element={<HomePage />} />
            <Route path="/admin/logout" element={<LogoutPage />} />
            <Route
                path="/admin/go"
                element={<RenderPage component={GoPage} pageStore={GoPageStore} />}
            />
            <Route
                path="/admin/login"
                element={
                    <RenderPage
                        component={LoginPage}
                        pageStore={LoginPageStore}
                        requireAuth={false}
                    />
                }
            />
            <Route
                path="/admin/password-reset-request"
                element={
                    <RenderPage
                        component={PasswordResetRequestPage}
                        pageStore={PasswordResetRequestPageStore}
                        requireAuth={false}
                    />
                }
            />
            <Route
                path="/admin/password-reset/:token"
                element={
                    <RenderPage
                        component={PasswordResetPage}
                        pageStore={PasswordResetPageStore}
                        requireAuth={false}
                    />
                }
            />
            <Route
                path="/admin/media-list"
                element={<RenderPage component={MediaListPage} pageStore={MediaListPageStore} />}
            />
            <Route
                path="/admin/media-create"
                element={
                    <RenderPage component={MediaCreatePage} pageStore={MediaCreatePageStore} />
                }
            />
            <Route
                path="/admin/media-create/success"
                element={
                    <RenderPage
                        component={MediaCreateSuccessPage}
                        pageStore={MediaCreateSuccessPageStore}
                    />
                }
            />
            <Route
                path="/admin/:mediaId/documents/:documentId"
                element={
                    <RenderPage component={DocumentEditPage} pageStore={DocumentEditPageStore} />
                }
            />
            <Route
                path="/admin/:mediaId/documents"
                element={
                    <RenderPage component={DocumentListPage} pageStore={DocumentListPageStore} />
                }
            />
            <Route
                path="/admin/:mediaId/revisions/:documentRevisionId/detail"
                element={
                    <RenderPage
                        component={RevisionDetailPage}
                        pageStore={DocumentRevisionDetailPageStore}
                    />
                }
            />
            <Route
                path="/admin/:mediaId/revisions/:documentRevisionId"
                element={
                    <RenderPage component={DocumentEditPage} pageStore={DocumentEditPageStore} />
                }
            />
            <Route
                path="/admin/:mediaId/revisions"
                element={
                    <RenderPage component={RevisionListPage} pageStore={RevisionListPageStore} />
                }
            />
            <Route
                path="/admin/:mediaId/document-groups/:key"
                element={
                    <RenderPage
                        component={DocumentGroupEditPage}
                        pageStore={DocumentGroupEditPageStore}
                    />
                }
            />
            <Route
                path="/admin/:mediaId/document-groups"
                element={
                    <RenderPage
                        component={DocumentGroupListPage}
                        pageStore={DocumentGroupListPageStore}
                    />
                }
            />
            <Route
                path="/admin/:mediaId/users/:id"
                element={<RenderPage component={UserEditPage} pageStore={UserEditPageStore} />}
            />
            <Route
                path="/admin/:mediaId/users"
                element={<RenderPage component={UserListPage} pageStore={UserListPageStore} />}
            />
            <Route
                path="/admin/:mediaId/import"
                element={<RenderPage component={ImportPage} pageStore={ImportPageStore} />}
            />
            <Route
                path="/admin/:mediaId/migration"
                element={<RenderPage component={MigrationPage} pageStore={MigrationPageStore} />}
            />
            <Route
                path="/admin/:mediaId/media-publishing"
                element={
                    <RenderPage
                        component={MediaPublishingPage}
                        pageStore={MediaPublishingPageStore}
                    />
                }
            />
            <Route
                path="/admin/:mediaId/media-delete"
                element={
                    <RenderPage component={MediaDeletePage} pageStore={MediaDeletePageStore} />
                }
            />

            <Route
                path="/admin/:mediaId/media-settings"
                element={
                    <RenderPage component={MediaSettingsPage} pageStore={MediaSettingsPageStore} />
                }
            />
            <Route
                path="/admin/profile"
                element={<RenderPage component={ProfilePage} pageStore={ProfilePageStore} />}
            />
            <Route
                path="/admin/password-change"
                element={
                    <RenderPage
                        component={PasswordChangePage}
                        pageStore={PasswordChangePageStore}
                    />
                }
            />
            <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
    );
};
